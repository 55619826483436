import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  InputAdornment,
  Chip,
  Stack,
  MenuItem
} from '@mui/material';
import { COMPANY_TYPES_NAME } from 'constants/companyGeneralInfo';
import PropTypes from 'prop-types';
import { ControlledTextfield } from 'components';
import { COMPANY_STATUS } from 'constants/companyStatus';
import { COMPANY_STATUS_MENU } from './constants';

const CompanyDetailInfor = ({ detailCompany, control, errors, openEditCompanyInfo }) => {

  const renderCompanyStatusItems = () => {
    const transformedCompanyStatusMenu = COMPANY_STATUS_MENU?.map((item) => ({
      ...item,
      disabled: detailCompany?.isSkipBankInfo && item.value === COMPANY_STATUS.VERIFIED
    }))

    return transformedCompanyStatusMenu.map((item) => (
      <MenuItem key={item.value} value={item.value} disabled={item.disabled}>
        {item.label}
      </MenuItem>
    ))
  }

  return (
    <Box>
      <Card elevation={0}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                  disabled={!openEditCompanyInfo}
                fullWidth
                label="Company Name"
                name="companyName"
                variant="outlined"
                defaultValue={detailCompany?.companyName}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Company Type"
                name="companyType"
                variant="outlined"
                defaultValue={
                  detailCompany?.types
                    ?.map((item) => item.name)
                    ?.join(', ')
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Company Form"
                name="companyForm"
                variant="outlined"
                defaultValue={detailCompany?.companyForm}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Description"
                name="description"
                variant="outlined"
                multiline
                rows={3}
                defaultValue={detailCompany?.description}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Card elevation={0}>
        <CardHeader title="Banking account" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Name of Account"
                name="bankAccountName"
                variant="outlined"
                defaultValue={detailCompany?.bankAccountName}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Sort code"
                name="sortCode"
                variant="outlined"
                defaultValue={detailCompany?.sortCode}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Account number"
                name="accountNumber"
                variant="outlined"
                defaultValue={detailCompany?.accountNumber}
              />
            </Grid>
  
            <Grid item md={4} xs={12}>
              <TextField
               disabled
                fullWidth
                label="Name of Bank"
                name="bankName"
                variant="outlined"
                defaultValue={detailCompany?.bankName}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label="IBAN number"
                name="ibanNumber"
                variant="outlined"
                defaultValue={detailCompany?.ibanNumber}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label="BIC (Swift code)"
                name="swiftCode"
                variant="outlined"
                defaultValue={detailCompany?.swiftCode}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Payment Term"
                name="paymentTerm"
                variant="outlined"
                defaultValue={detailCompany?.paymentTerm}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">day(s)</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Arrival Report Due"
                name="arrivalDueHour"
                variant="outlined"
                defaultValue={detailCompany?.arrivalDueHour}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">hour(s)</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
               disabled={!openEditCompanyInfo}
                fullWidth
                label="Final Reconciliation Period"
                name="arrivalDueDay"
                variant="outlined"
                defaultValue={detailCompany?.arrivalDueDay}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">day(s)</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Card elevation={0}>
        <CardHeader title="Company status" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <ControlledTextfield
                fullWidth
                label="Company status"
                name="companyStatus"
                variant="outlined"
                select
                required
                disabled={!openEditCompanyInfo}
                control={control}
                error={!!errors.companyStatus}
                helperText={errors.companyStatus?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {renderCompanyStatusItems()}
              </ControlledTextfield>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Card elevation={0}>
        <CardHeader title="Attachment" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Stack direction="row" spacing={1}>
                {detailCompany?.attachment?.map((item) => (
                  <Chip
                    key={item.id}
                    label={item.name}
                    color="primary"
                    component="a"
                    href={item.url}
                    clickable
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
} 

CompanyDetailInfor.propTypes = {
  detailCompany: PropTypes.instanceOf(Object),
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  openEditCompanyInfo: PropTypes.bool
};
CompanyDetailInfor.defaultProps = {
  detailCompany: {},
  errors: {},
  control: {},
  openEditCompanyInfo: false
};

export default CompanyDetailInfor;
