import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Divider, Drawer, List, Typography } from '@mui/material';
import {
  BarChart,
  Lock as LockIcon,
  Settings as SettingsIcon,
  List as ListIcon,
  Business as BusinessIcon,
  Person as PersonIcon,
  PersonAdd as PersonAddIcon,
  PeopleAlt as PeopleAltIcon,
  Group as GroupIcon,
  Email as EmailIcon,
  AccountBalance as AccountBalanceIcon,
  Category as CategoryIcon,
  Paid as PaidIcon,
} from '@mui/icons-material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useGetMeQuery } from 'services/adminUserApi';
import NavItem from './NavItem';
import axios from 'axios';

const items = [
  {
    href: '/',
    icon: BarChart,
    title: 'Dashboard',
  },
  {
    href: '/users',
    icon: PeopleAltIcon,
    title: 'Admin Users',
  },
  {
    href: '/companies',
    icon: BusinessIcon,
    title: 'Companies',
  },
  {
    href: '/company-registration',
    icon: BusinessIcon,
    title: 'Company Registration',
  },
  {
    href: '/user-list',
    icon: GroupIcon,
    title: 'Users',
  },
  {
    href: '/donate-email',
    icon: EmailIcon,
    title: 'Donate emails',
  },
  {
    href: '/brand',
    icon: AccountBalanceIcon,
    title: 'Brands',
  },
  {
    href: '/sub-category',
    icon: CategoryIcon,
    title: 'Produce Types',
  },
  {
    href: '/currency',
    icon: PaidIcon,
    title: 'Currency',
  },
  {
    href: '/account',
    icon: PersonIcon,
    title: 'Account',
  },
  {
    navigationRoute:
      'https://europe-west2-frupro-prod.cloudfunctions.net/ExportReportData?apiKey=L85JeFMRnP3RmmQCJEzKafn5GRgc5r7gkobHT5pp',
    icon: ListAltIcon,
    title: 'Excel Report Data',
  },
  {
    navigationRoute:
      'https://frupro-dev-api-gateway-1-ctoncblt.nw.gateway.dev/api/admin/authfuncs/get-excel',
    icon: ListAltIcon,
    title: 'Tracking Report',
  },
  {
    href: '/settings',
    icon: SettingsIcon,
    title: 'Settings',
  },
];

const Sidebar = () => {
  const { data, refetch } = useGetMeQuery();

  useEffect(() => {
    refetch();
  }, []);


  const getTrackingReport = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('token')).accessToken;

      const response = await axios.get(
        'https://frupro-dev-api-gateway-1-ctoncblt.nw.gateway.dev/api/admin/authfuncs/get-excel',
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2,
        }}
      >
        <Avatar
          component={RouterLink}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64,
          }}
          to="/account"
        />
        <Typography
          color="textPrimary"
          variant="h5"
          sx={{
            py: 1,
          }}
        >
          {data?.fullName}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {data?.role}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              onClick={getTrackingReport}
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              navigationRoute={item.navigationRoute}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open
      variant="persistent"
      PaperProps={{
        sx: {
          width: 256,
          top: 64,
          height: 'calc(100% - 64px)',
        },
      }}
    >
      {content}
    </Drawer>
  );
};

export default Sidebar;
