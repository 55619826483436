import { adminUserApi } from 'services/adminUserApi';
import { authenticationApi } from 'services/authenticationApi';
import { companyApi } from 'services/companyApi';
import { donateEmailsApi } from 'services/donateEmailsApi';
import { companyRegistrationApi } from 'services/companyRegistrationApi';
import errorHandler from 'services/errorHandler';
import { infoApi } from 'services/info';
import { brandApi } from 'services/brandApi';
import { impersonation } from 'services/impersonation';

export const setupMiddleware = (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(
    authenticationApi.middleware,
    adminUserApi.middleware,
    companyApi.middleware,
    donateEmailsApi.middleware,
    infoApi.middleware,
    companyRegistrationApi.middleware,
    brandApi.middleware,
    impersonation.middleware,
    errorHandler
  );
