export const initData = {
  companyStatus: ""
}

export const COMPANY_STATUS_MENU = [
  {
    label: "View Only",
    value: "APPROVED"
  },
  {
    label: "Full Trading Account",
    value: "VERIFIED"
  }
];

export const CHANGE_STATUS_DIALOG_DESCRIPTION = "The company is sending the company request in Company Registration. By clicking Ok button. You will approve all available requests"

export const UPDATE_SUCCESS_MESSAGE = "Update company info successfully!"
