import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Chip,
  Checkbox,
  FormControlLabel,
  Stack,
} from '@mui/material';
import { formatLongDate } from 'utils';
import { COMPANY_STATUS, COMPANY_REQUEST_TYPE } from 'constants/companyStatus';


export default function ConfidentialInfo({
  companyRequest,
  companyInfo,
  isCurrent,
  isRequested,
  isShowBankInfo
}) {
  let companyRequestData = {};

  if (isCurrent) {
    companyRequestData = companyRequest?.oldData;
  } else if (isRequested) {
    companyRequestData = companyRequest?.newData;
  }

  const renderStatus = () => {
    let text = '';
    let bgcolor = null;
    let color = 'white';
    if (companyRequest?.status === COMPANY_STATUS.APPROVED) {
      text = 'Confirmed';
      bgcolor = 'success.main';
    } else if (companyRequest?.status === COMPANY_STATUS.PENDING) {
      text = 'Pending';
      bgcolor = 'primary.main';
      color = 'white';
    } else if (companyRequest?.status === COMPANY_STATUS.REJECT) {
      text = 'Rejected';
      bgcolor = 'error.main';
    } else if (companyRequest?.status === COMPANY_STATUS.CANCELLED) {
      text = 'Cancelled';
    }
    if (bgcolor) {
      return (
        <Chip
          label={text}
          sx={{
            bgcolor,
            color,
          }}
        />
      );
    }
    return <Chip label={text} />;
  };

  const renderRequestType = () => {
    let text = '';
    let bgcolor = null;
    let color = 'white';
    if (companyRequest?.requestType === COMPANY_REQUEST_TYPE.NEW) {
      text = 'New';
      bgcolor = 'success.main';
    } else if (companyRequest?.requestType === COMPANY_REQUEST_TYPE.CHANGE) {
      text = 'Change';
      bgcolor = 'primary.main';
      color = 'white';
    }
    if (bgcolor) {
      return (
        <Chip
          label={text}
          sx={{
            bgcolor,
            color,
          }}
        />
      );
    }
    return <Chip label={text} />;
  };
  
  const renderBankingSection = () => (
    <Card elevation={0}>
      <CardHeader title="Banking account" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Name of Account"
              name="bankAccountName"
              variant="outlined"
              defaultValue={companyInfo?.bankAccountName}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Sort code"
              name="sortCode"
              variant="outlined"
              defaultValue={
                companyInfo?.sortCode
              }
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Account number"
              name="accountNumber"
              variant="outlined"
              defaultValue={
                companyInfo?.accountNumber
              }
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Name of Bank"
              name="bankName"
              variant="outlined"
              defaultValue={companyInfo?.bankName}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="IBAN number"
              name="ibanNumber"
              variant="outlined"
              defaultValue={companyInfo?.ibanNumber}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="BIC (Swift code)"
              name="swiftCode"
              variant="outlined"
              defaultValue={companyInfo?.swiftCode}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Payment Terms"
              name="paymentTerm"
              variant="outlined"
              defaultValue={companyInfo?.paymentTerm}
              InputProps={{
                endAdornment: "day(s)",
              }}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Arrival Report Due"
              name="arrivalDueHour"
              variant="outlined"
              defaultValue={companyInfo?.arrivalDueHour}
              InputProps={{
                endAdornment: "hour(s)",
              }}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Final Reconciliation Period"
              name="arrivalDueDay"
              variant="outlined"
              defaultValue={companyInfo?.arrivalDueDay}
              InputProps={{
                endAdornment: "day(s)",
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Box>
      <Card elevation={0}>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={3} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Company House Registration (CRN)"
                name="companyHouseRegistration"
                variant="outlined"
                defaultValue={companyRequestData?.companyHouseRegistration}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                disabled
                fullWidth
                label="VAT Number"
                name="VATNumber"
                variant="outlined"
                defaultValue={companyRequestData?.VATNumber}
              />
            </Grid>
            {isRequested && (
              <Grid item md={3} xs={12}>
                {renderStatus()}
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Card elevation={0}>
        <CardHeader title="Request information" />
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Request by"
                name="email"
                variant="outlined"
                defaultValue={companyRequest?.user?.fullName}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Email"
                name="email"
                variant="outlined"
                defaultValue={companyRequest?.user?.email}
              />
            </Grid>
            {isRequested && (
              <Grid item md={4} xs={12}>
                {renderRequestType()}
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item md={4} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    label="Request date"
                    name="email"
                    variant="outlined"
                    defaultValue={formatLongDate(companyRequest?.createdAt, {
                      isMonthShort: true,
                    })}
                  />
                </Grid>
                {
                  companyRequest?.approvedAt && (
                    <Grid item md={4} xs={12}>
                      <TextField
                        disabled
                        fullWidth
                        label="Verify date"
                        name="email"
                        variant="outlined"
                        defaultValue={formatLongDate(companyRequest?.approvedAt, {
                          isMonthShort: true,
                        })}
                      />
                    </Grid>
                  )
                }
              </Grid>
              
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Card elevation={0}>
        <CardHeader title="Principal place of business" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={6}>
              <TextField
                disabled
                fullWidth
                label="Country"
                name="principalPlaceCountry"
                variant="outlined"
                defaultValue={
                  companyInfo?.principalPlaceCountry?.name
                }
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                disabled
                fullWidth
                label="Region / State"
                name="principalPlaceState"
                variant="outlined"
                defaultValue={companyRequestData?.principalPlaceState?.name}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                disabled
                fullWidth
                label="City"
                name="principalPlaceCity"
                variant="outlined"
                defaultValue={companyRequestData?.principalPlaceCity?.name}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                disabled
                fullWidth
                label="Post code"
                name="principalPlacePostCode"
                variant="outlined"
                defaultValue={companyRequestData?.principalPlacePostCode}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Trading address"
                name="principalPlaceAddress"
                variant="outlined"
                multiline
                rows={3}
                defaultValue={companyRequestData?.principalPlaceAddress}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      {companyInfo && isShowBankInfo && renderBankingSection()}
      <Divider />
      <Card elevation={0}>
        <CardHeader
          title="Registered address"
          action={
            <FormControlLabel
              label="Same as Principal Place of Business Trading Address"
              disabled
              labelPlacement="start"
              control={
                <Checkbox
                  defaultChecked={companyRequestData?.isSamePrincipalPlace}
                />
              }
            />
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={6}>
              <TextField
                disabled
                fullWidth
                label="Country"
                name="registerCountry"
                variant="outlined"
                defaultValue={companyRequestData?.registerCountry?.name}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                disabled
                fullWidth
                label="Region / State"
                name="registerState"
                variant="outlined"
                defaultValue={companyRequestData?.registerState?.name}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                disabled
                fullWidth
                label="City"
                name="registerCity"
                variant="outlined"
                defaultValue={companyRequestData?.registerCity?.name}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                disabled
                fullWidth
                label="Post code"
                name="registerPostCode"
                variant="outlined"
                defaultValue={companyRequestData?.registerPostCode}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Register address"
                name="registerAddress"
                variant="outlined"
                multiline
                rows={3}
                defaultValue={companyRequestData?.registerAddress}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Card elevation={0}>
        <CardHeader title="Attachment" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Stack direction="row" spacing={1}>
                {companyRequest?.company?.attachment.map((item) => (
                  <Chip
                    key={item.id}
                    label={item.name}
                    color="primary"
                    component="a"
                    href={item.url}
                    clickable
                  />
                ))}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

ConfidentialInfo.propTypes = {
  companyRequest: PropTypes.instanceOf(Object),
  companyInfo: PropTypes.instanceOf(Object),
  isCurrent: PropTypes.bool,
  isRequested: PropTypes.bool,
  isShowBankInfo: PropTypes.bool,
}

ConfidentialInfo.defaultProps = {
  companyRequest: {},
  companyInfo: {},
  isCurrent: false,
  isRequested: false,
  isShowBankInfo: false,
}
