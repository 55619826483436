import { combineReducers } from 'redux';
import usersReducer from 'modules/admin-users/users.slice';
import { authenticationApi } from 'services/authenticationApi';
import { adminUserApi } from 'services/adminUserApi';
import { companyApi } from 'services/companyApi';
import { donateEmailsApi } from 'services/donateEmailsApi';
import { infoApi } from 'services/info';
import { companyRegistrationApi } from 'services/companyRegistrationApi';
import { brandApi } from 'services/brandApi';
import { impersonation } from 'services/impersonation';

const rootReducer = combineReducers({
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [adminUserApi.reducerPath]: adminUserApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [donateEmailsApi.reducerPath]: donateEmailsApi.reducer,
  [infoApi.reducerPath]: infoApi.reducer,
  [companyRegistrationApi.reducerPath]: companyRegistrationApi.reducer,
  [brandApi.reducerPath]: brandApi.reducer,
  [impersonation.reducerPath]: impersonation.reducer,
  users: usersReducer,
});

export default rootReducer;
