import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './baseQueryWithReAuth';

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    listCompanies: builder.query({
      query: ({ page, limit, keyword, order }) => ({
        url: `/api/companies?includes=user&page=${page}&limit=${limit}&keyword=${keyword}&order=${order}`,
      }),
      transformResponse: (response) => response,
    }),
    listDetailCompany: builder.query({
      query: (companyId) => ({
        url: `api/companies/${companyId}/latest-published-request-confidentiality?includes=user`,
      }),
      transformResponse: (response) => response,
    }),
    getDetailCompany: builder.query({
      query: (companyId) => ({
        url: `api/companies/${companyId}`,
      }),
      transformResponse: (response) => response,
    }),
    listEmployeeCompany: builder.query({
      query: ({ companyId, page, limit, keyword }) => ({
        url: `/api/companies/${companyId}/employees?page=${page}&limit=${limit}&keyword=${keyword}`,
      }),
      transformResponse: (response) => response,
    }),
    promoteCompanyAdmin: builder.mutation({
      query: ({ companyId, promoteUserIds, degradeUserIds }) => ({
        url: `api/companies/${companyId}/promotes`,
        method: 'POST',
        body: { promoteUserIds, degradeUserIds },
      }),
      transformResponse: (response) => response,
    }),
    updateCompanyLocation: builder.mutation({
      query: ({ companyId, lat, long }) => ({
        url: `api/companies/${companyId}/locations`,
        method: 'PUT',
        body: { lat, long },
      }),
      transformResponse: (response) => response,
      async onQueryStarted(
        { companyId, lat, long, query },
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;
        dispatch(
          companyApi.util.updateQueryData('listCompanies', query, (draft) => {
            const index = draft.items.findIndex(
              (item) => item.id === companyId
            );
            // eslint-disable-next-line no-param-reassign
            draft.items[index].location = {
              lat,
              long,
            };
          })
        );
      },
    }),
  }),
});

export const {
  useListCompaniesQuery,
  useListDetailCompanyQuery,
  useListEmployeeCompanyQuery,
  useGetDetailCompanyQuery,
  usePromoteCompanyAdminMutation,
  useUpdateCompanyLocationMutation,
} = companyApi;
