import React, { useState, useEffect } from 'react';
import { DONATE_EMAIL_PATH } from 'constants/paths';
import { makeStyles } from '@mui/styles';
import { FruproPage } from 'components';
import {
  Box,
  Tab,
  Button
} from '@mui/material';
import {
  TabContext,
  TabList,
  TabPanel
} from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetDonateEmailQuery } from 'services/donateEmailsApi';
import { useParams, useHistory } from 'react-router-dom';
import DonateCenterEmails from './DonationCenterEmails';
import DonationCenterInfor from './DonationCenterInfor';


const useStyles = makeStyles((theme) => ({
  tabsList: {
    position: 'relative'
  },
  backButton: {
    position: 'absolute',
    right: 0
  }
}))

const DonationCenterContainer = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('donationInfor');
  const [donationCenter, setDonationCenter] = useState();
  const { data, refetch, isLoading } = useGetDonateEmailQuery(id);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (data) {
      setDonationCenter(data);
    }
  }, [data]);
  
  const tabsList = [
    {
      key: 'donationInfor',
      label: 'Donation center info',
      component: 
        <DonationCenterInfor 
          donationCenter={donationCenter}
          setDonationCenter={setDonationCenter}
          refetchDonationCenter={refetch}
          donateId={id}
        />
    },
    {
      key: 'donationEmails',
      label: 'Donation center emails',
      component: <DonateCenterEmails donateId={id} />
    },
  ]

  useEffect(() => {
    const initTab = tabsList[0]?.key;
    setActiveTab(initTab);
  }, []);

  return (
    <FruproPage>
      <LoadingIndicator loading={isLoading} />
      {
        donationCenter && (
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={activeTab}>
              <Box 
                sx={{ 
                  borderBottom: 1,
                  borderColor: 'divider'
                }}
              >
                <TabList 
                  onChange={handleChange} 
                  aria-label="Tabs"
                  className={classes.tabsList}
                >
                  {
                    tabsList?.map((tab) => (
                      <Tab key={tab?.key} label={tab?.label} value={tab?.key} />
                    ))
                  }
                  <Button 
                    startIcon={<ArrowBackIcon />} 
                    color="primary"
                    variant="contained"
                    onClick={() => history.push(DONATE_EMAIL_PATH)}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                </TabList>
              </Box>
            {
              tabsList?.map((tab) => (
                <TabPanel 
                  key={tab?.key} 
                  value={tab?.key} 
                >
                  {tab?.component}
                </TabPanel>
              ))
            }
            </TabContext>    
          </Box>
        )
      }
    </FruproPage>
  )
}

export default DonationCenterContainer;
