import { FruproPage } from 'components';
import LoadingIndicator from 'components/LoadingIndicator';
import React, {useState, useEffect} from 'react';
import { useParams, useHistory } from 'react-router';
import { useGetProductTypesQuery } from 'services/info';
import {
  Container,
  Divider,
  Typography,
  Button,
  Box
} from '@mui/material';
import { SUB_CATEGORY_PATH } from 'constants/paths';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Images from './Images';

const SubCategory = () => {
  const { id } = useParams();
  const history = useHistory();
  const { data, refetch, isLoading, isFetching } = useGetProductTypesQuery();
  const [defaulImages, setDefaultImages] = useState();
  const [shouldRefetch, setShouldRefech] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState('')

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }
  }, [shouldRefetch]);

  useEffect(() => {
    const subCategories = data?.reduce((prevResult, currentItem) => {
      const result = [...prevResult];
      return [...result, ...currentItem?.subProductType];
    }, []);
    const newSubCategory = subCategories?.find(item => (item._id || item.id) === id);
    if (newSubCategory) {
      setDefaultImages(newSubCategory?.images || []);
      setSubCategoryName(newSubCategory.name);
    }
  }, [id, data]);

  return (
    <Container maxWidth='xl'>
      <FruproPage>
        <LoadingIndicator isLoading={isLoading || isFetching} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            py: 2
          }}
        >
          <Typography variant="h6">
            Default images for {subCategoryName}
          </Typography>
          <Button 
            startIcon={<ArrowBackIcon />} 
            color="primary"
            variant="contained"
            size="small"
            onClick={() => history.push(SUB_CATEGORY_PATH)}
          >
            Back
          </Button>
        </Box>
        <Divider />
        {
          defaulImages && (
            <Images 
              images={defaulImages}
              setDefaultImages={setDefaultImages}
              subId={id}
              setShouldRefech={setShouldRefech}
            />
          )
        }
      </FruproPage>
    </Container>
    
  )
}

export default SubCategory;
