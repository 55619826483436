import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E77228',
    },
    secondary: {
      main: '#BEBEBE',
    },
    success: {
      main: '#2CCB8D',
    },
    error: {
      main: '#F6133D',
    },
    default: {
      main: '#E8E8E8',
      text: '#151B22',
    },
    initial: {
      main: '#FFF',
    },
    grey: {
      main: '#BEBEBE',
      light: '#F5F5F5',
      label: '#858688',
      text: '#8B8B8B',
    },
    yellow: {
      main: '#F4C327',
      light: 'rgba(244,195,39,0.2)',
    },
    blue: {
      main: '#04B0ED',
    },
    background: {
      main: 'rgb(239, 240, 243)',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    body1: {
      fontSize: 14,
    },
    subtitle1: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    body2: {
      color: '#858688',
      fontSize: '12px',
    },
  },
  components: {
    MuiInputBase: {
      root: {
        padding: '10px 12px 5px 12px',
        background: ' #f7f7f7',
      },
      inputMultiline: {
        padding: '10px 12px 5px 12px',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#151B22',
        fontWeight: 500,
        fontSize: '16px',
      },
      formControl: {
        top: '-4px',
      },
    },
  },
});

export { theme };
