import { ACTION } from "constants/action";
import { toast } from "react-toastify";

export const showToastMessage = (data) => {
  const { userName, companyName, action } = data;

  switch (action) {
    case ACTION.PROMOTE_ADMIN:
      toast.info(
        `You have added ${userName} to ${companyName} as a Company Admin!`
      );
      break;
    default:
      break;
  }
}