import { createApi } from '@reduxjs/toolkit/query/react';
import { ADMIN_USER_STATUS } from 'constants/adminUserStatus';
import { TOKEN } from 'constants/localStorageKeys';
import { queryObjSerialize } from 'utils';
import { baseQueryWithReAuth } from './baseQueryWithReAuth';

export const adminUserApi = createApi({
  reducerPath: 'adminUserApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    updatePassword: builder.mutation({
      query: ({ oldPassword, newPassword }) => ({
        url: '/api/admin/update-password',
        method: 'PUT',
        body: { oldPassword, newPassword },
      }),
      transformResponse: (response) => response,
    }),
    getMe: builder.query({
      query: () => ({
        url: '/api/admin/me',
        method: 'GET',
      }),
      transformResponse: (response) => response,
    }),
    updateInfo: builder.mutation({
      query: ({ firstName, lastName, phoneNumber }) => ({
        url: '/api/admin/update-info',
        method: 'PUT',
        body: { firstName, lastName, phoneNumber },
      }),
      transformResponse: (response) => response,
      async onQueryStarted(
        { firstName, lastName, phoneNumber },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: updatedUserInfo } = await queryFulfilled;
          const patchResult = dispatch(
            adminUserApi.util.updateQueryData('getMe', undefined, (draft) => {
              Object.assign(draft, updatedUserInfo);
            })
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    listAdminUsers: builder.query({
      query: ({ page, limit, keyword }) => ({
        url: `/api/admin/admin-users?page=${page}&limit=${limit}&keyword=${keyword}`,
      }),
      transformResponse: (response) => response,
    }),
    createAdminUser: builder.mutation({
      query: ({ email }) => ({
        url: '/api/admin/admin-users',
        method: 'POST',
        body: { email: email?.toLowerCase() },
      }),
      transformResponse: (response) => response,
    }),
    getUserList: builder.query({
      query: (queryParams) => {
        const queryString = queryObjSerialize(queryParams);
        return {
          url: `api/users?includes=company&${queryString}`,
        };
      },
      transformResponse: (response) => response,
    }),
    blockUsers: builder.mutation({
      query: ({ userIds, blockReason }) => ({
        url: 'api/block-users',
        method: 'POST',
        body: { userIds, blockReason },
      }),
      transformResponse: (response) => response,
    }),
    unblockUsers: builder.mutation({
      query: ({ userIds }) => ({
        url: 'api/block-users',
        method: 'PUT',
        body: { userIds },
      }),
      transformResponse: (response) => response,
    }),
    blockAdminUser: builder.mutation({
      query: ({ adminId, blockReason }) => ({
        url: `/api/admin/admin-users/${adminId}/block`,
        method: 'PUT',
        body: { blockReason },
      }),
      transformResponse: (response) => response,
      async onQueryStarted({ adminId, query }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          adminUserApi.util.updateQueryData(
            'listAdminUsers',
            query,
            (draft) => {
              const index = draft.items.findIndex(
                (item) => item.id === adminId
              );
              // eslint-disable-next-line no-param-reassign
              draft.items[index].status = ADMIN_USER_STATUS.BLOCKED;
            }
          )
        );
      },
    }),
    unBlockAdminUser: builder.mutation({
      query: ({ adminId }) => ({
        url: `/api/admin/admin-users/${adminId}/unblock`,
        method: 'PUT',
      }),
      transformResponse: (response) => response,
      async onQueryStarted({ adminId, query }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          adminUserApi.util.updateQueryData(
            'listAdminUsers',
            query,
            (draft) => {
              const index = draft.items.findIndex(
                (item) => item.id === adminId
              );
              // eslint-disable-next-line no-param-reassign
              draft.items[index].status = ADMIN_USER_STATUS.ACTIVATED;
            }
          )
        );
      },
    }),
    deleteAdminUser: builder.mutation({
      query: ({ adminId }) => ({
        url: `/api/admin/admin-users/${adminId}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response,
      async onQueryStarted({ adminId, query }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          adminUserApi.util.updateQueryData(
            'listAdminUsers',
            query,
            (draft) => {
              // eslint-disable-next-line no-param-reassign
              draft.items = draft.items.filter((item) => item.id !== adminId);
            }
          )
        );
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/api/admin/auth/logout',
        method: 'POST',
      }),
      transformResponse: (response) => {
        localStorage.removeItem(TOKEN);
        return response;
      },
    }),
  }),
});

export const {
  useUpdatePasswordMutation,
  useUpdateInfoMutation,
  useListAdminUsersQuery,
  useGetUserListQuery,
  useCreateAdminUserMutation,
  useBlockUsersMutation,
  useUnblockUsersMutation,
  useGetMeQuery,
  useBlockAdminUserMutation,
  useUnBlockAdminUserMutation,
  useDeleteAdminUserMutation,
  useLogoutMutation,
} = adminUserApi;
