/* eslint-disable react/display-name */
import React, { useState, useCallback } from 'react';
import {
  adminUserApi,
  useBlockAdminUserMutation,
  useCreateAdminUserMutation,
  useDeleteAdminUserMutation,
  useListAdminUsersQuery,
  useUnBlockAdminUserMutation,
} from 'services/adminUserApi';
import { ControlledTextfield, FormDialog, FruproPage, Table } from 'components';
import { ADMIN_USER_STATUS } from 'constants/adminUserStatus';
import { Button, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useGetMeQuery } from 'services/adminUserApi';
import { ADMIN_USER_ROLES } from 'constants/adminUserRole';
import { useDispatch } from 'react-redux';
import LoadingIndicator from 'components/LoadingIndicator';
import ActionAdminUsers from './ActionAdminUsers';

const columns = (
  performBlockAdminUser,
  performUnBlockAdminUser,
  performDeleteAdminUser
) => [
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'fullName', label: 'Full name', minWidth: 170 },
  { id: 'phoneNumber', label: 'Mobile phone', minWidth: 170 },
  {
    id: 'status',
    label: 'Status',
    minWidth: 100,
    content: (row) => {
      let text = '';
      let bgcolor = null;
      let color = 'white';
      if (row.status === ADMIN_USER_STATUS.ACTIVATED) {
        text = 'Active';
        bgcolor = 'success.main';
      }
      if (row.status === ADMIN_USER_STATUS.UNACTIVATED) {
        text = 'Inactive';
        bgcolor = 'secondary.main';
        color = 'black';
      }
      if (row.status === ADMIN_USER_STATUS.BLOCKED) {
        text = 'Block';
        bgcolor = 'error.main';
      }
      if (bgcolor) {
        return (
          <Chip
            label={text}
            sx={{
              bgcolor,
              color,
            }}
          />
        );
      }
      return <Chip label={text} />;
    },
  },
  {
    id: 'actions',
    label: '',
    minWidth: 80,
    content: (row) => {
      const temp = 1;
      return (
        <ActionAdminUsers
          data={row}
          performBlockAdminUser={performBlockAdminUser}
          performUnBlockAdminUser={performUnBlockAdminUser}
          performDeleteAdminUser={performDeleteAdminUser}
        />
      );
    },
  },
];

const schema = yup
  .object({
    email: yup
      .string()
      .email('Email must be a valid email')
      .required('Email is required')
      .matches(/^[A-Za-z0-9._%+-]+@[Ff]rupro.com$/, {
        message: 'Only accept email with domain @frupro.com',
      }),
  })
  .required();

const AdminUsers = () => {
  const [openCreateUser, setOpenCreateUser] = useState(false);

  const {
    control,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm({ mode: 'all', resolver: yupResolver(schema) });

  const [
    performCreateAdminUser,
    { isSuccess, data: adminUserCreated, isLoading: isLoadingCreateAdminUser },
  ] = useCreateAdminUserMutation();
  const dispatch = useDispatch();
  const {
    data: userInfo,
    isLoading: isLoadingUserInfo,
    refetch: refetchUserInfo,
    isFetching: isFetchingUserInfo,
  } = useGetMeQuery();

  const [performBlockAdminUser, { isSuccess: isBlockAdminUserSuccess }] =
    useBlockAdminUserMutation();

  const [performUnBlockAdminUser, { isSuccess: isUnBlockAdminUserSuccess }] =
    useUnBlockAdminUserMutation();

  const [performDeleteAdminUser, { isSuccess: isDeleteAdminUserSuccess }] =
    useDeleteAdminUserMutation();

  useEffect(() => {
    refetchUserInfo();
  }, []);

  const handleItemCreated = useCallback(
    (query) => {
      if (adminUserCreated) {
        dispatch(
          adminUserApi.util.updateQueryData(
            'listAdminUsers',
            query,
            (draftPosts) => {
              draftPosts.items.unshift(adminUserCreated);
            }
          )
        );
      }
    },
    [adminUserCreated]
  );

  const handleCloseForm = () => {
    clearErrors();
    reset({ email: '' });
    setTimeout(() => {
      setOpenCreateUser(false);
    }, 100);
  };
  const handleCreateUser = (data) => {
    performCreateAdminUser(data);
    reset({ email: '' });
    setTimeout(() => {
      setOpenCreateUser(false);
    }, 100);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('User created successfully, please check the email', {
        toastId: 'create-admin-user-success-id',
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isBlockAdminUserSuccess) {
      toast.success('Block admin user successfully', {
        toastId: 'create-admin-user-success-id',
      });
    }
  }, [isBlockAdminUserSuccess]);

  useEffect(() => {
    if (isUnBlockAdminUserSuccess) {
      toast.success('Unblock admin user successfully', {
        toastId: 'create-admin-user-success-id',
      });
    }
  }, [isUnBlockAdminUserSuccess]);

  useEffect(() => {
    if (isDeleteAdminUserSuccess) {
      toast.success('Delete admin user successfully', {
        toastId: 'create-admin-user-success-id',
      });
    }
  }, [isDeleteAdminUserSuccess]);

  if (isLoadingUserInfo || isFetchingUserInfo) {
    return <LoadingIndicator loading />;
  }

  if (userInfo?.role !== ADMIN_USER_ROLES.SUPER_ADMIN) {
    return (
      <h2 style={{ paddingLeft: '16px' }}>
        You don&apos;t have permission to view list of admin users
      </h2>
    );
  }

  return (
    <FruproPage>
      {isLoadingUserInfo ? null : (
        <>
          <FormDialog
            open={openCreateUser}
            onClose={handleCloseForm}
            buttons={[
              {
                onClick: handleCloseForm,
                label: 'Cancel',
                styles: { marginRight: '8px' },
              },
              {
                onClick: handleSubmit(handleCreateUser),
                label: 'Create',
                variant: 'contained',
              },
            ]}
            title="Create new admin user"
          >
            <ControlledTextfield
              control={control}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              error={!!errors.email}
              helperText={errors.email?.message}
              sx={{ marginBottom: '16px', minWidth: '400px' }}
            />
          </FormDialog>
          <Table
            columns={columns(
              performBlockAdminUser,
              performUnBlockAdminUser,
              performDeleteAdminUser
            )}
            title="Admin users list"
            useHookApi={useListAdminUsersQuery}
            onItemCreated={handleItemCreated}
            isLoadingData={isLoadingCreateAdminUser}
          >
            <Button
              onClick={() => setOpenCreateUser(true)}
              startIcon={<AddIcon />}
              variant="contained"
            >
              Create new admin user
            </Button>
          </Table>
        </>
      )}
    </FruproPage>
  );
};

AdminUsers.propTypes = {};

export default AdminUsers;
