import React, { useState } from 'react';
import {
  IconButton,
  InputAdornment,
} from '@mui/material';
import { 
  Visibility as VisibilityIcon, 
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { ControlledTextfield } from 'components';

export default function PasswordField(props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <ControlledTextfield
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword((prev) => !prev)}
              edge="end"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
