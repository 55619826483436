import { FruproPage } from 'components';
import { useRouteMatch, useHistory } from 'react-router-dom';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Paper,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  LinearProgress,
  Box,
  Tooltip,
} from '@mui/material';
import { RemoveRedEye as RemoveRedEyeIcon } from '@mui/icons-material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useGetProductTypesQuery } from 'services/info';

const SubCategories = () => {
  const { data, refetch, isLoading, isFetching } = useGetProductTypesQuery();
  const [subCategories, setSubCategories] = useState();
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const productTypes = data;
    const newSubCategories = productTypes?.reduce((prevResult, currentItem) => {
      const result = [...prevResult];
      return [...result, ...currentItem?.subProductType];
    }, []);
    setSubCategories(newSubCategories);
  }, [data]);

  const columns = [
    { id: 'name', label: 'Produce Type' },
    {
      id: 'action',
      label: '',
      align: 'center',
      content: (row) => (
        <Tooltip title="View detail">
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(`${path}/${row?._id || row?.id}`);
            }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <FruproPage>
      <h2 style={{ marginTop: 0 }}>Produce Types</h2>
      {isLoading || isFetching ? <LinearProgress /> : <Box height="4px" />}
      <Paper sx={{ width: '100%', overflow: 'hidden', mt: 1 }} elevation={2}>
        <TableContainer>
          <MUITable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={`id-${column.id}`}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: 'bold',
                      fontSize: 14,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {subCategories?.map((item, itemIndex) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={item._id || item.id || itemIndex}
                >
                  {columns.map((column, index) => {
                    const value = column.content
                      ? column.content(item, index)
                      : item[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          fontSize: 14,
                          cursor: 'pointer',
                        }}
                        onClick={
                          column.onClick ? () => column.onClick(item) : null
                        }
                      >
                        {column.handleShowText
                          ? column.handleShowText(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
      </Paper>
    </FruproPage>
  );
};

export default SubCategories;
