import {
  NavLink as RouterLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';

const NavItem = ({
  href,
  icon: Icon,
  title,
  onClick,
  navigationRoute,
  ...rest
}) => {
  const location = useLocation();
  const history = useHistory();

  const active = href
    ? !!matchPath(location.pathname, {
        path: href,
        exact: true,
      })
    : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
      }}
      {...rest}
    >
      {navigationRoute ? (
        <a href={title !== 'Tracking Report' &&  navigationRoute} style={{ textDecoration: 'none' }}>
          <Button
            onClick={title === 'Tracking Report' && onClick}
            sx={{
              color: 'text.secondary',
              fontWeight: 'medium',
              justifyContent: 'flex-start',
              letterSpacing: 0,
              py: 1.25,
              textTransform: 'none',
              width: '100%',
              ...(active && {
                color: 'primary.main',
              }),
              '& svg': {
                mr: 1,
              },
            }}
          >
            {Icon && <Icon size="20" />}
            <span>{title}</span>
          </Button>
        </a>
      ) : (
        <Button
          component={RouterLink}
          sx={{
            color: 'text.secondary',
            fontWeight: 'medium',
            justifyContent: 'flex-start',
            letterSpacing: 0,
            py: 1.25,
            textTransform: 'none',
            width: '100%',
            ...(active && {
              color: 'primary.main',
            }),
            '& svg': {
              mr: 1,
            },
          }}
          to={href}
        >
          {Icon && <Icon size="20" />}
          <span>{title}</span>
        </Button>
      )}
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

NavItem.defaultProps = {
  href: '#',
  icon: null,
  title: '',
};

export default NavItem;
