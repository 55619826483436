import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from '@mui/material';import PropTypes from 'prop-types';

export default function BankInfo({ companyInfo }) {

  return (
    <Card elevation={0}>
      <CardHeader title="Banking account" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Name of Account"
              name="bankAccountName"
              variant="outlined"
              defaultValue={companyInfo?.bankAccountName}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Sort code"
              name="sortCode"
              variant="outlined"
              defaultValue={
                companyInfo?.sortCode
              }
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Account number"
              name="accountNumber"
              variant="outlined"
              defaultValue={
                companyInfo?.accountNumber
              }
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Name of Bank"
              name="bankName"
              variant="outlined"
              defaultValue={companyInfo?.bankName}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="IBAN number"
              name="ibanNumber"
              variant="outlined"
              defaultValue={companyInfo?.ibanNumber}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="BIC (Swift code)"
              name="swiftCode"
              variant="outlined"
              defaultValue={companyInfo?.swiftCode}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Payment Terms"
              name="paymentTerm"
              variant="outlined"
              defaultValue={companyInfo?.paymentTerm}
              InputProps={{
                endAdornment: "day(s)",
              }}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Arrival Report Due"
              name="arrivalDueHour"
              variant="outlined"
              defaultValue={companyInfo?.arrivalDueHour}
              InputProps={{
                endAdornment: "hour(s)",
              }}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              disabled
              fullWidth
              label="Final Reconciliation Period"
              name="arrivalDueDay"
              variant="outlined"
              defaultValue={companyInfo?.arrivalDueDay}
              InputProps={{
                endAdornment: "day(s)",
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

BankInfo.propTypes = {
  companyInfo: PropTypes.instanceOf(Object),
}

BankInfo.defaultProps = {
  companyInfo: {},
}