import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ControlledTextfield, FormDialog } from 'components';
import { companyApi } from 'services/companyApi';
import { useSelector } from 'react-redux';
import { findKeyIndex } from 'utils/findKey';

const schema = yup
  .object({
    lat: yup
      .string()
      .required('Lat is required')
      .matches(
        /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
        {
          message: 'Invalid latitude',
        }
      ),
    long: yup
      .string()
      .required('Long is required')
      .matches(
        /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
        {
          message: 'Invalid longitude',
        }
      ),
  })
  .required();

const UpdateLocation = (props) => {
  const { open, setOpen, locationInfo, performUpdate } = props;

  const dataSelector = useSelector(
    (state) => state[companyApi.reducerPath].queries
  );

  const queries =
    dataSelector[
      Object.keys(dataSelector)[
        findKeyIndex(Object.keys(dataSelector), 'listCompanies')
      ]
    ]?.originalArgs;

  const {
    control,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const handleUpdate = (data) => {
    performUpdate({
      companyId: locationInfo.companyId,
      lat: data.lat,
      long: data.long,
      query: queries,
    });
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    clearErrors();
  };

  return (
    <FormDialog
      title="Update company location"
      maxWidth="md"
      open={open}
      onClose={handleCancel}
      buttons={[
        {
          onClick: handleCancel,
          label: 'Cancel',
          styles: { marginRight: '8px' },
        },
        {
          onClick: handleSubmit(handleUpdate),
          label: 'Update',
          variant: 'contained',
        },
      ]}
    >
      <ControlledTextfield
        control={control}
        margin="normal"
        required
        fullWidth
        id="lat"
        label="Lat"
        name="lat"
        error={!!errors.lat}
        helperText={errors.lat?.message}
        sx={{ marginBottom: '16px', minWidth: '400px' }}
        defaultValue={locationInfo.location?.lat}
      />
      <ControlledTextfield
        control={control}
        margin="normal"
        required
        fullWidth
        id="long"
        label="Long"
        name="long"
        error={!!errors.long}
        helperText={errors.long?.message}
        sx={{ marginBottom: '16px', minWidth: '400px' }}
        defaultValue={locationInfo.location?.long}
      />
    </FormDialog>
  );
};

UpdateLocation.propTypes = {
  open: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  locationInfo: PropTypes.object,
  setOpen: PropTypes.func,
  performUpdate: PropTypes.func,
};

UpdateLocation.defaultProps = {
  open: false,
  locationInfo: {},
  setOpen: () => {},
  performUpdate: () => {},
};

export default UpdateLocation;
