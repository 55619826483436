import React from "react";
import { Select, MenuItem } from "@mui/material";
import PropTypes from 'prop-types';

const PhoneInput = ({ countries, country, onChange, disabled, ...others }) => (
  <Select
    disabled={disabled}
    disableUnderline
    value={country}
    onChange={onChange}
    {...others}
    renderValue={(value) => (
      <>{value.phone ? `(+${value.phone})` : value.countryCode}</>
    )}
  >
    {countries?.map((item) => (
      <MenuItem key={item.countryCode} value={item}>
        {item.countryName} ({item.countryCode})
      </MenuItem>
    ))}
  </Select>
);


PhoneInput.propTypes = {
  countries: PropTypes.instanceOf(Array), 
  country: PropTypes.instanceOf(Object), 
  onChange: PropTypes.func, 
  disabled: PropTypes.bool
}

PhoneInput.defaultProps = {
  countries: null, 
  country: null, 
  onChange: () => {}, 
  disabled: false
}

export default PhoneInput;
