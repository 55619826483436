import { FruproPage } from 'components';
import React from 'react';

const DashBoard = () => (
  <FruproPage style={{ height: '82vh' }}>
    <></>
  </FruproPage>
);

DashBoard.propTypes = {};

export default DashBoard;
