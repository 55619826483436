import { createApi } from '@reduxjs/toolkit/query/react';
import { queryObjSerialize } from 'utils';
import { baseQueryWithReAuth } from './baseQueryWithReAuth';

export const donateEmailsApi = createApi({
  reducerPath: 'donateEmailsApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getDonateEmails: builder.query({
      query: (queryParams) => {
        const queryString = queryObjSerialize(queryParams);
        return {
          url: `api/donate-emails?${queryString}`
        }
      },
      transformResponse: (response) => response,
    }),
    getDonateEmail: builder.query({
      query: (donateEmailId) => ({
        url: `api/donate-emails/${donateEmailId}`
      }),
      transformResponse: (response) => response,
    }),
    createDonateEmail: builder.mutation({
      query: (data) => ({
        url: 'api/donate-emails',
        method: 'POST',
        body: data
      }),
      transformResponse: (response) => response,
    }),
    updateDonateEmail: builder.mutation({
      query: (params) => {
        const { data, donateId } = params;
        return {
          url: `api/donate-emails/${donateId}`,
          method: 'PUT',
          body: data
        }
      },
      transformResponse: (response) => response,
    }),
    getEmailsList: builder.query({
      query: (params) => {
        const {donateId, ...queryParams} = params;
        const queryString = queryObjSerialize(queryParams);
        return {
          url: `api/donate-emails/${donateId}/emails?${queryString}`
        }
      },
      transformResponse: (response) => response,
    }),
    addEmails: builder.mutation({
      query: (params) => {
        const { emails, donateId } = params;
        return {
          url: `api/donate-emails/${donateId}/emails`,
          method: 'POST',
          body: { emails }
        }
      },
      transformResponse: (response) => response,
    }),
    deleteEmail: builder.mutation({
      query: (params) => {
        const { email, donateId } = params;
        return {
          url: `api/donate-emails/${donateId}/emails`,
          method: 'DELETE',
          body: { email }
        }
      },
      transformResponse: (response) => response,
    }),
  }),
});

export const {
  useGetDonateEmailsQuery,
  useGetDonateEmailQuery,
  useCreateDonateEmailMutation,
  useGetEmailsListQuery,
  useUpdateDonateEmailMutation,
  useAddEmailsMutation,
  useDeleteEmailMutation
} = donateEmailsApi;
