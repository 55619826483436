/* eslint-disable react/prop-types */
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  background: theme.palette.initial.main,
}));

export default function Page({ children, ...restProps }) {
  return (
    <StyledPaper elevation={0} {...restProps}>
      {children}
    </StyledPaper>
  );
}
