import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Visibility,
  VisibilityOff,
  LockOutlined,
  Login as LoginIcon,
} from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  Avatar,
  CssBaseline,
  Link as MaterialLink,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { useLoginMutation } from 'services/authenticationApi';
import { HOME_PAGE_PATH } from 'constants/paths';
import { ControlledTextfield } from 'components';
import { toast } from 'react-toastify';

const schema = yup
  .object({
    email: yup
      .string()
      .email('Email must be a valid email')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/^(?=.*[a-z]).*$/, {
        message: 'Password must be at least one lowercase',
      })
      .matches(/^(?=.*[A-Z]).*$/, {
        message: 'Password must be at least one uppercase',
      })
      .matches(/^(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).*$/, {
        message: 'Password must be at least one special character',
      }),
  })
  .required();

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <MaterialLink color="inherit" href="http://localhost:3000">
        FruPro
      </MaterialLink>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const customId = 'toast-login-id';

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const [performLogin, { isSuccess, error, isLoading }] = useLoginMutation();
  const handleLogin = (data) => {
    performLogin(data);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: 'all', resolver: yupResolver(schema) });

  if (isSuccess) {
    return <Redirect to={HOME_PAGE_PATH} />;
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(handleLogin)}
            noValidate
            sx={{ mt: 1 }}
          >
            <ControlledTextfield
              control={control}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <ControlledTextfield
              control={control}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              startIcon={<LoginIcon />}
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
              color="primary"
            >
              Sign in
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                <Link
                  to="/forgot-password"
                  variant="body2"
                  style={{ color: 'blue' }}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </>
  );
}
