/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const ControlledTextfield = (props) => {
  const { control, name, defaultValue, ...otherProps } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required: true }}
      render={({ field }) => <TextField {...field} {...otherProps} />}
    />
  );
};

ControlledTextfield.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  defaultValue: PropTypes.any,
};

ControlledTextfield.defaultProps = {
  control: null,
  name: '',
  defaultValue: '',
};

export default ControlledTextfield;
