/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog as MUIDialog, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(MUIDialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root.MuiDialog-paper': {
    padding: theme.spacing(2),
  },
}));

const FormDialog = (props) => {
  const { title, open, onClose, children, buttons, maxWidth, fullWidth } =
    props;
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      {title ? <h2 style={{ marginTop: 0 }}>{title}</h2> : null}
      {children}
      <Grid container justifyContent="flex-end" mt={2}>
        {buttons.map((item, index) => (
          <Grid item key={index}>
            <Button
              onClick={item.onClick}
              variant={item.variant}
              sx={item.styles}
              disabled={item.disabled}
              color={item.color}
            >
              {item.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </StyledDialog>
  );
};

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node,
  buttons: PropTypes.array,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
};

FormDialog.defaultProps = {
  onClose: () => {},
  children: null,
  buttons: [],
  title: '',
  maxWidth: 'sm',
  fullWidth: false,
};

export default FormDialog;
