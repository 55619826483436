import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';

import PropTypes from 'prop-types';
import { COMPANY_STATUS, COMPANY_REQUEST_TYPE } from 'constants/companyStatus';
import { formatLongDate } from 'utils';

/* Since there is no data for the subscription from the backend, it is statically created here. */

function createData(packageName, packageStartD, packageEndD, nextBilingD) {
    return { packageName, packageStartD, packageEndD, nextBilingD };
}

const tableData = [
    createData('Basic', '1/09/22', '1/11/22', '2/11/22'),
    createData('Pro', '2/09/22', '2/11/22', '3/11/22'),
    createData('Pro', '3/09/22', '3/11/22', '4/11/22'),
    createData('Basic', '4/09/22', '4/11/22', '5/11/22'),
];

const CompanySubscriptionDetails = ({ detailCompany }) => {
  const renderStatus = () => {
    let text = '';
    let bgcolor = null;
    let color = 'white';
    if (detailCompany?.status === COMPANY_STATUS.APPROVED) {
      text = 'Approved';
      bgcolor = 'success.main';
    } else if (detailCompany?.status === COMPANY_STATUS.PENDING) {
      text = 'Pending';
      bgcolor = 'primary.main';
      color = 'white';
    } else if (detailCompany?.status === COMPANY_STATUS.REJECT) {
      text = 'Rejected';
      bgcolor = 'error.main';
    } else if (detailCompany?.status === COMPANY_STATUS.CANCELLED) {
      text = 'Cancelled';
    }
    if (bgcolor) {
      return (
        <Chip
          label={text}
          sx={{
            bgcolor,
            color,
          }}
        />
      );
    }
    return <Chip label={text} />;
  };

  return (
    <Box>
      <Card elevation={0}>
        <CardHeader title="Active Subscription" />
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={3} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Current Subscription Package"
                name="currentSubscPack"
                variant="outlined"
                defaultValue={detailCompany.subscription?.name ? (
                  detailCompany?.subscription?.name
                ):(
                  'Package name not found.'
                )}
               
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Subscription Start Date"
                name="subscStartDate"
                variant="outlined"
                defaultValue={detailCompany.subscription?.start ? (
                  formatLongDate(detailCompany?.subscription?.start, {
                    isMonthShort: true,
                  })
                ):(
                  'Start date not found.'
                )}
             
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Subscription End Date"
                name="subsEndDate"
                variant="outlined"
                defaultValue={detailCompany.subscription?.end ? (
                  formatLongDate(detailCompany?.subscription?.end, {
                    isMonthShort: true,
                  })
                ):(
                  'End date not found.'
                )}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Next Billing Date"
                name="nextBillingDate"
                variant="outlined"
                defaultValue={detailCompany.subscription?.nextBilling ? (
                  formatLongDate(detailCompany?.subscription?.nextBilling, {
                    isMonthShort: true,
                  })
                ):(
                  'Billing date not found.'
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      {/* <Card elevation={0}>
        <CardHeader title="Past Subscription" />
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Past Subscription Package</TableCell>
                    <TableCell>Subscription Start Date</TableCell>
                    <TableCell>Subscription End Date</TableCell>
                    <TableCell>Next Biling Date</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {tableData.map((item) => (
                    <TableRow
                    key={item.packageName}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">{item.packageName}</TableCell>
                    <TableCell>{item.packageStartD}</TableCell>
                    <TableCell>{item.packageEndD}</TableCell>
                    <TableCell>{item.nextBilingD}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
      </Card> */}
    </Box>
  );
};

CompanySubscriptionDetails.propTypes = {
  detailCompany: PropTypes.instanceOf(Object),
};
CompanySubscriptionDetails.defaultProps = {
  detailCompany: {},
};

export default CompanySubscriptionDetails;
