export const USER_ROLE = [
  {
    value: 0,
    name: 'All role',
  },
  {
    value: 'COMPANY_ADMIN',
    name: 'Company admin',
  },
  {
    value: 'NORMAL_USER',
    name: 'Employee',
  },
];

export const USER_ROLES_CONSTANT = {
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  NORMAL_USER: 'NORMAL_USER',
};
