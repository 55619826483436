/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { FruproPage, Table, ControlledTextfield, FormDialog } from 'components';
import {
  useGetDonateEmailsQuery,
  useCreateDonateEmailMutation,
} from 'services/donateEmailsApi';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Tooltip, IconButton } from '@mui/material';
import {
  RemoveRedEye as RemoveRedEyeIcon,
  Add as AddIcon,
} from '@mui/icons-material';

const schema = yup
  .object({
    name: yup.string().required('Name is required'),
    address: yup.string().required('Address is required'),
    long: yup
      .string()
      .required('Long is required')
      .matches(
        /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
        {
          message: 'Invalid longitude',
        }
      ),
    lat: yup
      .string()
      .required('Lat is required')
      .matches(
        /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
        {
          message: 'Invalid latitude',
        }
      ),
  })
  .required();

const DonateEmails = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const [openAddDonationDialog, setOpenAddDonationDialog] = useState(false);

  const defaultValues = {
    name: '',
    address: '',
    long: '',
    lat: '',
  };

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const [performCreateDonation, { isSuccess, isLoading }] =
    useCreateDonateEmailMutation();

  const handleCreateDonation = (data) => {
    performCreateDonation(data);
    reset(defaultValues);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDonationDialog(false);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Create donation successfully');
      setOpenAddDonationDialog(false);
    }
  }, [isSuccess]);

  const columns = [
    {
      id: 'donationCenter',
      label: 'Donation Center',
      content: (row) => row?.name,
      onClick: (row) => {
        history.push(`${path}/${row?.id}`);
      },
    },
    {
      id: 'adress',
      label: 'Adress',
      content: (row) => row?.address || '--',
    },
    {
      id: 'long',
      label: 'Long',
      content: (row) => row?.location?.long,
    },
    {
      id: 'lat',
      label: 'Lat',
      content: (row) => row?.location?.lat,
    },
    {
      id: 'action',
      label: '',
      align: 'right',
      content: (row) => (
        <Tooltip title="View detail">
          <IconButton
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(`${path}/${row?.id}`);
            }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <FruproPage>
      <FormDialog
        open={openAddDonationDialog}
        onClose={handleCloseAddDialog}
        buttons={[
          {
            onClick: () => handleCloseAddDialog(),
            label: 'Cancel',
            styles: { marginRight: '8px' },
          },
          {
            onClick: handleSubmit(handleCreateDonation),
            label: 'Create',
            variant: 'contained',
            disabled: isLoading,
          },
        ]}
        title="Create new donation center"
      >
        <ControlledTextfield
          control={control}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <ControlledTextfield
          control={control}
          margin="normal"
          required
          fullWidth
          id="address"
          label="Address"
          name="address"
          error={!!errors.address}
          helperText={errors.address?.message}
        />
        <ControlledTextfield
          control={control}
          margin="normal"
          required
          fullWidth
          id="long"
          label="Long"
          name="long"
          error={!!errors.long}
          helperText={errors.long?.message}
        />
        <ControlledTextfield
          control={control}
          margin="normal"
          required
          fullWidth
          id="lat"
          label="Lat"
          name="lat"
          error={!!errors.lat}
          helperText={errors.lat?.message}
        />
      </FormDialog>
      <Table
        title="Donate emails"
        columns={columns}
        useHookApi={useGetDonateEmailsQuery}
        shouldRefetch={isSuccess}
      >
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => setOpenAddDonationDialog(true)}
        >
          Add new donation center
        </Button>
      </Table>
    </FruproPage>
  );
};

export default DonateEmails;
