import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  contents: {
    paddingLeft: 24,
    paddingTop: 16,
    margin: '-4px 0',
    listStyle: 'decimal',
    '& li': {
      fontSize: 14,
      padding: '4px 0'
    }
  }
}));

const ApproveNewRequestDialog = (props) => {
  const { open, handleClose, onSubmit } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        Approve new request
      </DialogTitle>
      <DialogContent>
        <Typography>
          This company has been approved onto the platform by a FruPro admin 
          from the Admin Panel but they will still have limited access:
        </Typography>
        <Box className={classes.contents} component="ul">
          <Box component="li">
            <b>NOT</b> be able to make an offer for other product listings on the platform
          </Box>
          <Box component="li">
            <b>NOT</b> be able to receive any offers for any product listings they have created on the platform
          </Box>
          <Box component="li">
            Able to create product listings
          </Box>
          <Box component="li">
            Able to message other users on the platform including having a company group chat
          </Box>
          <Box component="li">
            Able to add colleagues to their company
          </Box>
          <Box component="li">
            <b>NOT</b> be able to see other product listings on the platform’s pricing
          </Box>
          <Box component="li">
            <b>NOT</b> see &quot;Add to cart&quot; or &quot;Place offer&quot; buttons
          </Box>
          <Box component="li">
            The product listings created by these users will <b>NOT</b> show the pricing from their product listings, the product listings 
            they have created will have no pricing shown to other users of the platform, it will be blank to other users
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={onSubmit} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ApproveNewRequestDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func
};

ApproveNewRequestDialog.defaultProps = {
  open: false,
  handleClose: () => {},
  onSubmit: () => {},
};

export default ApproveNewRequestDialog;
