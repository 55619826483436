export const COMPANY_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECT: 'REJECT',
  CANCELLED: 'CANCELLED',
  VERIFIED: 'VERIFIED'
};

export const COMPANY_STATUS_LABEL = {
  PENDING: 'Non-Approved',
  APPROVED: 'View Only',
  VERIFIED: 'Full Trading Account',
  CANCELLED: 'CANCELLED',
  REJECT: 'REJECT',
}

export const COMPANY_REQUEST_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECT: 'REJECT',
  CANCELLED: 'CANCELLED',
  VERIFIED: 'VERIFIED'
};

export const COMPANY_REQUEST_STATUS_FILTER = [
  {
    name: 'All',
    value: 0,
  },
  {
    name: 'Pending',
    value: COMPANY_REQUEST_STATUS.PENDING,
  },
  {
    name: 'Approved',
    value: COMPANY_REQUEST_STATUS.APPROVED,
  },
  {
    name: 'Reject',
    value: COMPANY_REQUEST_STATUS.REJECT,
  },
  {
    name: 'Cancelled',
    value: COMPANY_REQUEST_STATUS.CANCELLED,
  },
];

export const COMPANY_REQUEST_TYPE = {
  CHANGE: 'CHANGE',
  NEW: 'NEW',
};

export const COMPANY_REQUEST_TYPE_FILTER = [
  {
    name: 'All',
    value: 0,
  },
  {
    name: 'New',
    value: COMPANY_REQUEST_TYPE.NEW,
  },
  {
    name: 'Change',
    value: COMPANY_REQUEST_TYPE.CHANGE,
  },
];
