/* eslint-disable */
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Header, Sidebar } from 'components';
import { Box } from '@mui/system';
import { useRef, useEffect } from 'react';
import { TOKEN } from 'constants/localStorageKeys';

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  display: 'flex',
  height: '100vh',
  overflow: 'hidden',
  width: '100%',
}));

const Layout = ({ children }) => {
  const logoutTimeOut = useRef();

  const EVENTS = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress"
  ];

  const logout = () => {
    localStorage.removeItem(TOKEN);
    window.location.reload();
  }

  const setTimeOutKick = () => {
    logoutTimeOut.current = setTimeout(logout, 1800 * 1000);
  }

  const clearTimeOutKick = () => {
    clearTimeout(logoutTimeOut.current);
  }

  const resetTimeOut = () => {
    clearTimeOutKick();
    setTimeOutKick();
  }

  const initEvent = () => {
    for (const i in EVENTS) {
      window.addEventListener(EVENTS[i], resetTimeOut);
    }
  }

  useEffect(() => {
    initEvent();
  }, []);

  return (
    <Root>
      <Header />
      <Sidebar />
      <Box
        paddingTop={8}
        paddingLeft={32}
        sx={{ width: 'calc(100% - 256px)', overflowY: 'scroll' }}
      >
        {children}
      </Box>
    </Root>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
