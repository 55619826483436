import React from 'react';
import { PropTypes } from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { PasswordField } from 'components';
import { PASSWORD_REGEX } from 'utils/constants';

const schema = yup.object().shape({
  password: yup.string().required('This is required'),
  newPassword: yup
    .string()
    .matches(
      PASSWORD_REGEX,
      '*Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
    )
    .required('This is required'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('newPassword'), null],
      '*New password and Confirm password must be match'
    )
    .matches(
      PASSWORD_REGEX,
      '*Confirm Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
    )
    .required('*This is required'),
});

const ChangePassword = ({ handleChangePassword, isLoading }) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({ mode: 'all', resolver: yupResolver(schema) });

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleChangePassword)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Card elevation={0}>
        <CardHeader
          title={<Typography variant="h5">Change password</Typography>}
        />
        <Divider />
        <CardContent sx={{ paddingBottom: 16 }}>
          <Grid container spacing={2}>
            <Grid item lg={4} xs={12}>
              <PasswordField
                control={control}
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <PasswordField
                control={control}
                margin="normal"
                required
                fullWidth
                id="newPassword"
                label="New password"
                name="newPassword"
                error={!!errors.newPassword}
                helperText={errors.newPassword?.message}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <PasswordField
                control={control}
                margin="normal"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm password"
                name="confirmPassword"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isLoading || !isValid}
          >
            {isLoading ? 'Updating' : 'Update'}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

ChangePassword.defaultProps = {
  handleChangePassword: () => {},
  isLoading: false,
};

ChangePassword.propTypes = {
  handleChangePassword: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default ChangePassword;
