import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from 'routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';

const App = () => {

  return(
    <Router>
    <AppRoutes />
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
    />
  </Router>
  )
  
}

  

export default App;
