import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LockOutlined } from '@mui/icons-material';
import {
  Avatar,
  Button,
  CssBaseline,
  Link as MaterialLink,
  Box,
  Typography,
  Container,
  Link,
  Grid,
  Alert,
} from '@mui/material';
import { ControlledTextfield, PasswordField } from 'components';
import {
  useActiveAccountMutation,
  useVerifyActiveCodeMutation,
} from 'services/authenticationApi';
import { toast } from 'react-toastify';
import { PASSWORD_REGEX } from 'utils/constants';
import { useHistory } from 'react-router';
import { LOGIN_PATH } from 'constants/paths';

const schema = yup.object().shape({
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  password: yup
    .string()
    .matches(
      PASSWORD_REGEX,
      '*Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
    )
    .required('This is required'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      '*Password and Confirm Password must be match'
    )
    .matches(
      PASSWORD_REGEX,
      '*Confirm Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
    )
    .required('*This is required'),
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <MaterialLink color="inherit" href="http://localhost:3000">
        FruPro
      </MaterialLink>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function CreatePassword() {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ mode: 'all', resolver: yupResolver(schema) });

  const [showCreatePasswordForm, setShowCreatePasswordForm] = useState(false);

  const history = useHistory();
  const activeCode = new URLSearchParams(history.location.search).get(
    'activeCode'
  );

  const [
    performVerifyActiveCode,
    {
      isLoading: isLoadingVerify,
      isSuccess: isSuccessVerify,
      error: errorVerify,
    },
  ] = useVerifyActiveCodeMutation();
  const [
    performActiveAccount,
    { isLoading: isLoadingActiveAccount, isSuccess: isSuccessActiveAccount },
  ] = useActiveAccountMutation();

  const handleCreatePassword = (data) => {
    performActiveAccount({
      password: data.password,
      activeCode,
      firstName: data.firstName,
      lastName: data.lastName,
    });
  };

  useEffect(() => {
    if (activeCode) {
      performVerifyActiveCode({ activeCode });
    }
  }, [activeCode]);

  useEffect(() => {
    if (isSuccessVerify) {
      setShowCreatePasswordForm(true);
    }
  }, [isSuccessVerify]);

  useEffect(() => {
    if (isSuccessActiveAccount) {
      const message =
        'Your password has been successfully created. Please login';
      toast.success(message);
      history.push(LOGIN_PATH);
    }
  }, [isSuccessActiveAccount]);

  return (
    <>
      {!showCreatePasswordForm && !isLoadingVerify && (
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minWidth: '400px',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Create Password
            </Typography>
            <Alert
              severity="error"
              sx={{ width: '92%', fontSize: '16px', marginTop: '16px' }}
            >
              {errorVerify?.data.message}
            </Alert>
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              color="primary"
              onClick={() => {
                history.push(LOGIN_PATH);
              }}
            >
              Back to login
            </Button>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Grid>
        </Container>
      )}
      {showCreatePasswordForm && (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Create Password
            </Typography>
            <Typography
              variant="body1"
              sx={{
                pt: 2,
              }}
            >
              Valid Password must be at least 8 characters including number,
              uppercase letter, lowercase letter and special special characters
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit(handleCreatePassword)}
              noValidate
              sx={{
                mt: 1,
                width: '100%',
              }}
            >
              <ControlledTextfield
                control={control}
                margin="normal"
                required
                fullWidth
                id="firstName"
                label="First name"
                name="firstName"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
              <ControlledTextfield
                control={control}
                margin="normal"
                required
                fullWidth
                id="lastName"
                label="Last name"
                name="lastName"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
              <PasswordField
                control={control}
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                error={!!errors.password}
                helperText={errors.password?.message}
              />
              <PasswordField
                control={control}
                margin="normal"
                required
                fullWidth
                id="confirm-password"
                label="Confirm password"
                name="confirmPassword"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                color="primary"
                disabled={isLoadingActiveAccount}
              >
                {isLoadingActiveAccount ? 'Submitting' : 'Submit'}
              </Button>
            </Box>
            <Link href={LOGIN_PATH}>Back to Sign in</Link>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      )}
    </>
  );
}
