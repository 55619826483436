import React, { useState, useEffect } from 'react';
import {
  Box,
  Tab,
  CircularProgress,
  Grid
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import PropTypes from 'prop-types';
import { COMPANY_REQUEST_STATUS } from 'constants/companyStatus';
import {
  useGetDetailCompanyQuery,
} from 'services/companyApi';
import ConfidentialInfo from './ConfidentialInfo';
import BankInfo from './BankInfo';

const CompanyRegistrationConInfor = ({ detailCompany }) => {
  const { 
    data: companyInfo,
    isLoading: isGetDetailCompanyLoading,
  } = useGetDetailCompanyQuery(detailCompany?.company?.id);
  
  const [activeTab, setActiveTab] = useState('currentConfidential');

  if (
    [
      COMPANY_REQUEST_STATUS.APPROVED,
      COMPANY_REQUEST_STATUS.REJECT
    ].includes(detailCompany.status)
  ) {
    return isGetDetailCompanyLoading ? (
      <Grid container justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
    ) : (
      <ConfidentialInfo
        companyRequest={detailCompany}
        companyInfo={companyInfo}
        isRequested
        isShowBankInfo
      />
    )
  }

  const tabsList = [
    {
      key: 'currentConfidential',
      label: 'Current confidential company info',
      component: (
        <ConfidentialInfo companyRequest={detailCompany} companyInfo={companyInfo} isCurrent />
      ),
    },
    {
      key: 'requestedConfidential',
      label: 'Requested Confidential info',
      component: <ConfidentialInfo companyRequest={detailCompany} companyInfo={companyInfo} isRequested  />,
    },
    {
      key: 'bankInfo',
      label: 'Requested Bank Info',
      component: <BankInfo companyInfo={companyInfo} />,
    },
  ];

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const initTab = tabsList[0]?.key;
    setActiveTab(initTab);
  }, []);

  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={activeTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="Tabs"
            >
              {tabsList?.map((tab) => (
                <Tab key={tab?.key} label={tab?.label} value={tab?.key} />
              ))}
            </TabList>
          </Box>
          {tabsList?.map((tab) => (
            <TabPanel key={tab?.key} value={tab?.key}>
              {tab?.component}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </>
  );
};

CompanyRegistrationConInfor.propTypes = {
  detailCompany: PropTypes.instanceOf(Object),
};
CompanyRegistrationConInfor.defaultProps = {
  detailCompany: {},
};

export default CompanyRegistrationConInfor;
