import { TOKEN } from 'constants/localStorageKeys';
import { baseQuery } from './baseQuery';

export const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const storageToken = localStorage.getItem(TOKEN);
    const refreshToken = storageToken && JSON.parse(storageToken).refreshToken;
    const refreshResult = await baseQuery(
      {
        url: '/api/admin/auth/refresh-token',
        method: 'POST',
        body: { refreshToken },
      },
      api,
      extraOptions
    );

    if (refreshResult.data) {
      // store the new token
      const token = JSON.stringify({
        accessToken: refreshResult.data.accessToken.token,
        refreshToken: refreshResult.data.refreshToken.token,
      });
      localStorage.setItem(TOKEN, token);
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);

      // force logout
      if (result.error && result.error.status === 401) {
        localStorage.removeItem(TOKEN);
        window.location.reload();
      }
    } else {
      // log out
      localStorage.removeItem(TOKEN);
      window.location.reload();
    }
  }
  return result;
};
