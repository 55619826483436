import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { LOGIN_PATH } from 'constants/paths';
import { Layout } from 'components';
import { TOKEN } from 'constants/localStorageKeys';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem(TOKEN);

  if (token) {
    return (
      <Layout>
        <Route component={Component} {...rest} />
      </Layout>
    );
  }

  return <Redirect to={LOGIN_PATH} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
