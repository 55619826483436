import { createApi } from '@reduxjs/toolkit/query/react';
import { COMPANY_REQUEST_STATUS } from 'constants/companyStatus';
import { queryObjSerialize } from 'utils';
import { baseQueryWithReAuth } from './baseQueryWithReAuth';

export const companyRegistrationApi = createApi({
  reducerPath: 'companyRegistrationApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    listCompanyRequests: builder.query({
      query: ({
        page,
        limit,
        keyword,
        status,
        requestType,
        createdAtFrom,
        createdAtTo,
        order,
        orderBy,
      }) => {
        const queryParams = queryObjSerialize({
          page,
          limit,
          keyword,
          status,
          requestType,
          createdAtFrom,
          createdAtTo,
          order,
          orderBy,
        });
        return {
          // url: `/api/company-requests?page=${page}&limit=${limit}&keyword=${keyword}`,
          url: `/api/company-requests?${queryParams}`,
        };
      },
      transformResponse: (response) => response,
    }),
    companyRequestDetail: builder.mutation({
      query: ({ companyRequestId }) => ({
        url: `/api/company-requests/${companyRequestId}`,
      }),
      transformResponse: (response) => response,
    }),
    approveCompanyRequest: builder.mutation({
      query: ({ companyRequestId, query }) => ({
        url: `/api/company-requests/${companyRequestId}/approve`,
        method: 'PUT',
      }),
      transformResponse: (response) => response,
      async onQueryStarted(
        { companyRequestId, query },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          const patchResult = dispatch(
            companyRegistrationApi.util.updateQueryData(
              'listCompanyRequests',
              query,
              (draft) => {
                const index = draft.items.findIndex(
                  (item) => item.id === companyRequestId
                );
                // eslint-disable-next-line no-param-reassign
                draft.items[index].status = COMPANY_REQUEST_STATUS.APPROVED;
              }
            )
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),
    rejectCompanyRequest: builder.mutation({
      query: ({ companyRequestId, rejectionReason }) => ({
        url: `/api/company-requests/${companyRequestId}/reject`,
        method: 'PUT',
        body: { rejectionReason },
      }),
      transformResponse: (response) => response,
      async onQueryStarted(
        { companyRequestId, query },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          const patchResult = dispatch(
            companyRegistrationApi.util.updateQueryData(
              'listCompanyRequests',
              query,
              (draft) => {
                const index = draft.items.findIndex(
                  (item) => item.id === companyRequestId
                );
                // eslint-disable-next-line no-param-reassign
                draft.items[index].status = COMPANY_REQUEST_STATUS.REJECT;
              }
            )
          );
        } catch (error) {
          console.log(error);
        }
      },
    }),
    verifyCompany: builder.mutation({
      query: ({ companyId }) => ({
        url: `api/companies/${companyId}/verify`,
        method: 'PUT',
      }),
      transformResponse: (response) => response,
    }),
    approveCompany: builder.mutation({
      query: ({ companyId }) => ({
        url: `api/companies/${companyId}/approve`,
        method: 'PUT',
      }),
      transformResponse: (response) => response,
    })
  }),
});

export const {
  useListCompanyRequestsQuery,
  useCompanyRequestDetailMutation,
  useApproveCompanyRequestMutation,
  useRejectCompanyRequestMutation,
  useApproveCompanyMutation,
  useVerifyCompanyMutation
} = companyRegistrationApi;
