import { createApi } from '@reduxjs/toolkit/query/react';
import { queryObjSerialize } from 'utils';
import { baseQueryWithReAuth } from './baseQueryWithReAuth';

export const brandApi = createApi({
  reducerPath: 'brandApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    listStatisticBrands: builder.query({
      query: ({ page, limit, keyword }) => {
        const queryParams = queryObjSerialize({ page, limit, keyword });
        return {
          url: `/api/statistic-brands?${queryParams}`,
        };
      },
      transformResponse: (response) => response,
    }),
  }),
});

export const { useListStatisticBrandsQuery } = brandApi;
