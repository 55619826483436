/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FruproPage, Table } from 'components';
import {
  companyRegistrationApi,
  useApproveCompanyRequestMutation,
  useCompanyRequestDetailMutation,
  useListCompanyRequestsQuery,
  useRejectCompanyRequestMutation,
} from 'services/companyRegistrationApi';
import { generateAddress } from 'utils/generateAddress';
import {
  COMPANY_REQUEST_STATUS,
  COMPANY_REQUEST_STATUS_FILTER,
  COMPANY_REQUEST_TYPE,
  COMPANY_REQUEST_TYPE_FILTER,
} from 'constants/companyStatus';
import { Chip, Grid, Link, MenuItem, Select } from '@mui/material';
import { format, isValid } from 'date-fns';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';

import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import LoadingIndicator from 'components/LoadingIndicator';
import ActionCompanyRegistration from './ActionCompanyRegistration';
import CompanyRegistrationDetail from './CompanyRegistrationDetail';

const useStyles = makeStyles(() => ({
  statusControl: {
    '& .MuiFilledInput-root': {
      padding: 0,
      borderRadius: 0,
    },
  },
}));

const generateColumns = (
  setOpen,
  performRequestDetail,
  performApproveCompanyRequest,
  performRejectCompanyRequest,
  setOrder,
  setShouldRefetch
) => [
  {
    id: 'requestNo',
    label: 'Request no',
    content: (data) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        component="button"
        variant="body2"
        onClick={() => {
          setOpen(true);
          performRequestDetail({ companyRequestId: data?.id });
        }}
        sx={{ fontSize: '14px' }}
      >
        {data?.code}
      </Link>
    ),
  },
  {
    id: 'companyName',
    label: 'Company name',
    minWidth: 180,
    content: (row) => <span>{row.company?.companyName}</span>,
    onSort: (order) => {
      if (order === 'asc') {
        setOrder('companyName');
      } else {
        setOrder('-companyName');
      }
    },
    sortKey: 'companyName',
  },
  {
    id: 'companyType',
    label: 'Company type',
    minWidth: 180,
    content: (row) => <span>{row.company?.companyType}</span>,
    onSort: (order) => {
      if (order === 'asc') {
        setOrder('companyType');
      } else {
        setOrder('-companyType');
      }
    },
    sortKey: 'companyType',
  },
  {
    id: 'companyHouseRegistration',
    label: 'Registration house number',
    minWidth: 100,
    content: (row) => <span>{row.company?.companyHouseRegistration}</span>,
  },
  {
    id: 'VATNumber',
    label: 'VAT number',
    minWidth: 180,
    content: (row) => <span>{row.company?.VATNumber}</span>,
    onSort: (order) => {
      if (order === 'asc') {
        setOrder('VATNumber');
      } else {
        setOrder('-VATNumber');
      }
    },
    sortKey: 'VATNumber',
  },
  {
    id: 'registerAddress',
    label: 'Register address',
    minWidth: 100,
    content: (data) => {
      const formattedData = {
        registerAddress: data.company?.registerAddress,
        registerCountry: data.company?.registerCountry?.name,
        registerState: data.company?.registerState?.name,
        registerCity: data.company?.registerCity?.name,
      };
      const address = generateAddress('register', formattedData);
      return <span>{address}</span>;
    },
  },
  {
    id: 'requestType',
    label: 'Request type',
    minWidth: 110,
    content: (row) => {
      let text = '';
      let bgcolor = null;
      let color = 'white';
      if (row.requestType === COMPANY_REQUEST_TYPE.NEW) {
        text = 'New';
        bgcolor = 'success.main';
      } else if (row.requestType === COMPANY_REQUEST_TYPE.CHANGE) {
        text = 'Change';
        bgcolor = 'primary.main';
        color = 'white';
      }
      if (bgcolor) {
        return (
          <Chip
            label={text}
            sx={{
              bgcolor,
              color,
            }}
          />
        );
      }
      return <Chip label={text} />;
    },
    onSort: (order) => {
      if (order === 'asc') {
        setOrder('requestType');
      } else {
        setOrder('-requestType');
      }
    },
    sortKey: 'requestType',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 150,
    content: (row) => {
      let text = '';
      let bgcolor = null;
      let color = 'white';

      switch (row.status) {
        case COMPANY_REQUEST_STATUS.APPROVED:
          text = 'Confirmed';
          bgcolor = 'success.main';
          break;
        case COMPANY_REQUEST_STATUS.PENDING:
          text = 'Pending';
          bgcolor = 'primary.main';
          color = 'white';
          break;
        case COMPANY_REQUEST_STATUS.REJECT:
          text = 'Rejected';
          bgcolor = 'error.main';
          break;
        case COMPANY_REQUEST_STATUS.CANCELLED:
          text = 'Cancelled';
          break;
        case COMPANY_REQUEST_STATUS.VERIFIED:
          text = 'Verifed';
          bgcolor = 'success.main';
          break;
        default:
          break;
      }
    
      if (bgcolor) {
        return (
          <Chip
            label={text}
            sx={{
              bgcolor,
              color,
            }}
          />
        );
      }
      return <Chip label={text} />;
    },
    onSort: (order) => {
      if (order === 'asc') {
        setOrder('status');
      } else {
        setOrder('-status');
      }
    },
    sortKey: 'status',
  },
  {
    id: 'requestBy',
    label: 'Request by',
    minWidth: 100,
    content: (row) => <span>{row.user?.fullName}</span>,
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    content: (row) => <span>{row.user?.email}</span>,
  },
  {
    id: 'requestDate',
    label: 'Request date',
    minWidth: 180,
    content: (row) => {
      const dateFormatted = format(
        new Date(row.createdAt),
        'yyyy - MM - dd HH:mm:ss'
      );
      return <span>{dateFormatted}</span>;
    },
    onSort: (order) => {
      if (order === 'asc') {
        setOrder('createdAt');
      } else {
        setOrder('-createdAt');
      }
    },
    sortKey: 'createdAt',
  },
  {
    id: 'verifyDate',
    label: 'Verify date',
    minWidth: 180,
    content: (row) => {
      const dateFormatted = row.approvedAt
        ? format(new Date(row.approvedAt), 'yyyy - MM - dd HH:mm:ss')
        : '';
      return <span>{dateFormatted}</span>;
    },
    onSort: (order) => {
      if (order === 'asc') {
        setOrder('approvedAt');
      } else {
        setOrder('-approvedAt');
      }
    },
    sortKey: 'approvedAt',
  },
  {
    id: 'actions',
    label: '',
    minWidth: 100,
    content: (row) => (
      <ActionCompanyRegistration
        setOpen={setOpen}
        performRequestDetail={performRequestDetail}
        rowData={row}
        performApproveCompanyRequest={performApproveCompanyRequest}
        performRejectCompanyRequest={performRejectCompanyRequest}
        setShouldRefetch={setShouldRefetch}
      />
    ),
    customStyle: {
      position: 'sticky',
      right: 0,
      background: 'white',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
];

const CompanyRegistration = (props) => {
  const classes = useStyles();

  const [openDetail, setOpenDetail] = useState(false);
  const [status, setStatus] = useState(0);
  const [requestType, setRequestType] = useState(0);
  const [createdAtFrom, setCreatedAtFrom] = useState(null);
  const [createdAtTo, setCreatedAtTo] = useState(null);
  const [order, setOrder] = useState('');
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const [performRequestDetail, { data, isLoading }] =
    useCompanyRequestDetailMutation();

  const [
    performApproveCompanyRequest,
    { 
      isSuccess: isApproveCompanyRequestSuccess,
      isLoading: isApproveCompanyRequestLoading
    },
  ] = useApproveCompanyRequestMutation();

  const [
    performRejectCompanyRequest,
    { 
      isSuccess: isRejectCompanyRequestSuccess,
      isLoading: isRejectCompanyRequestLoading
    },
  ] = useRejectCompanyRequestMutation();

  useEffect(() => {
    if (isApproveCompanyRequestSuccess) {
      toast.success('Company request has been approved successfully', {
        toastId: 'company-request-action-success-id',
      });
    }
  }, [isApproveCompanyRequestSuccess]);

  useEffect(() => {
    if (isRejectCompanyRequestSuccess) {
      toast.success('Company request has been rejected successfully', {
        toastId: 'company-request-action-success-id',
      });
    }
  }, [isRejectCompanyRequestSuccess]);

  const handleFilterChange = (e, filterType) => {
    const filterValue = e.target.value;

    if (filterType === 'status') {
      setStatus(filterValue);
    } else {
      setRequestType(filterValue);
    }
  };

  const renderFilter = () => (
    <Grid container spacing={1} justifyContent="end">
      <Grid item className={classes.statusControl}>
        <Select
          onChange={(e) => handleFilterChange(e, 'status')}
          value={status}
        >
          {COMPANY_REQUEST_STATUS_FILTER.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Select
          onChange={(e) => handleFilterChange(e, 'requestType')}
          value={requestType}
        >
          {COMPANY_REQUEST_TYPE_FILTER.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={createdAtFrom}
            label="Created from"
            onChange={(newValue) => {
              setCreatedAtFrom(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={createdAtTo}
            label="Created to"
            onChange={(newValue) => {
              setCreatedAtTo(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );

  return (
    <FruproPage>
      <LoadingIndicator 
        loading={
          isApproveCompanyRequestLoading 
          || isRejectCompanyRequestLoading
        }
      />
      <Table
        columns={generateColumns(
          setOpenDetail,
          performRequestDetail,
          performApproveCompanyRequest,
          performRejectCompanyRequest,
          setOrder,
          setShouldRefetch
        )}
        title="Company requests"
        useHookApi={useListCompanyRequestsQuery}
        statusFilter={status}
        otherQueryParams={{
          requestType,
          createdAtFrom:
            createdAtFrom && isValid(new Date(createdAtFrom))
              ? format(new Date(createdAtFrom), 'yyyy-MM-dd')
              : null,
          createdAtTo:
            createdAtTo && isValid(new Date(createdAtTo))
              ? format(new Date(createdAtTo), 'yyyy-MM-dd')
              : null,
        }}
        order={order}
        shouldRefetch={shouldRefetch}
        setShouldRefetch={setShouldRefetch}
      >
        {renderFilter()}
      </Table>
      <CompanyRegistrationDetail
        open={openDetail}
        onClose={() => setOpenDetail(false)}
        companyRequestDetail={data}
        isLoading={isLoading}
      />
    </FruproPage>
  );
};

CompanyRegistration.propTypes = {};

export default CompanyRegistration;
