import React from 'react';
import { Box, Container } from '@mui/material';
import {
  useUpdatePasswordMutation,
  useLogoutMutation,
} from 'services/adminUserApi';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LOGIN_PATH } from 'constants/paths';
import ChangePassword from './ChangePassword';

export default function Setting() {
  const [
    performUpdatePassword,
    { isSuccess: isUpdateSuccess, error, isLoading },
  ] = useUpdatePasswordMutation();
  const [performLogout, { isSuccess: isLogoutSuccess }] = useLogoutMutation();

  const history = useHistory();

  const handleChangePassword = (data) => {
    performUpdatePassword({
      oldPassword: data?.password,
      newPassword: data?.newPassword,
    });
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      toast.success(
        'Your password has been successfully updated. Please login again'
      );
      performLogout();
    }
  }, [error, isUpdateSuccess]);

  useEffect(() => {
    if (isLogoutSuccess) {
      history.push(LOGIN_PATH);
    }
  }, [isLogoutSuccess]);

  return (
    <Box
      sx={{
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth="lg">
        <ChangePassword
          handleChangePassword={handleChangePassword}
          isLoading={isLoading}
        />
      </Container>
    </Box>
  );
}
