/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { 
  Grid,
  TextField,
  Button,
  Box,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import {
  Add as AddIcon,
} from "@mui/icons-material";
import { uploadFile } from 'services/common';
import { useSetDefaultImagesMutation } from 'services/info';
import { toast } from "react-toastify";
import LoadingIndicator from 'components/LoadingIndicator';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  selectedImage: {
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: 'auto'
    }
  },
  smallImage: {
    '& img': {
      objectFit: 'cover',
      width: '100%',
      height: 'auto'
    }
  },
  addImage: {
    background: `${theme.palette.secondary.main} !important`,
    minHeight: 100,
    position: 'relative'
  },
  hidden: {
    display: 'none'
  },
  uploadImage: {
    display: 'block',
    height: "100%",
    width: "100%",
    backgroundColor: "#E8E8E8 !important",
    border: `1px dashed ${theme.palette.secondary.main} !important`,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",

    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      cursor: "pointer",
    },

    "& svg": {
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%,-50%)",
    },
  },
  deleteIcon: {
    position: 'absolute',
    top: '10%',
    right: '10%',
    color: 'white',
    background: 'black',
    height: 12,
    width: 12,
    fontSize: 12,
    '&:hover': {
      background: theme.palette.primary.main
    },
    '&.md': {
      fontSize: 14,
      height: 20,
      width: 20,
      top: '5%',
      right: '5%',
    }
  },
  selectImageBtn: {
    position: 'absolute',
    top: '70%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textTransform: 'capitalize',
    color: 'white',
    background: 'rgba(0,0,0,0.6) !important',
    width: 'fit-content',
    padding: 0,
    fontSize: 11,
    '&:hover': {
      color: theme.palette.primary.main
    },
  }
}));

const Images = ({ 
  images,
  setDefaultImages,
  subId,
  setShouldRefech
}) => {
  const classes = useStyles();
  const [isUploadFileLoading, setIsUploadFileLoading] = useState(false);

  const [performSetDefaultImages, {
    isSuccess,
    isLoading: isSetDefaultLoading
  }] = useSetDefaultImagesMutation();

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];

    try {
      setIsUploadFileLoading(true);
      const data = await uploadFile(file, 'productType');
      setDefaultImages([
        ...images,
        {
          ...data,
          url: URL.createObjectURL(file),
          isCover: images.length === 0
        }
      ]);
      setIsUploadFileLoading(false);
    } catch (error) {
      toast.error(error.message || "Added photo failed");
    }
  }

  const handleSaveImages = () => {
    const newImages = images?.map((item) => ({
      name: item.name,
      path: item.path,
      size: item.size,
      isCover: item.isCover,
    }));

    performSetDefaultImages({
      subId,
      images: newImages
    })
  }

  const handleDeleteImage = (item) => {
    const newDefaultImages = images.filter((image) => (
      image.path !== item.path
    ));

    if (!newDefaultImages[0]?.isCover && newDefaultImages.length > 0) {
      newDefaultImages[0] = {
        ...newDefaultImages[0],
        isCover: true
      }
    }

    setDefaultImages(newDefaultImages);
  }

  const handleSelectImage = (image) => {
    const imagesArray = [...images];
    let index1 = 0;
    let index2 = 0;

    imagesArray.forEach((item, index) => {
      if (item.isCover) {
        imagesArray[index] = {
          ...imagesArray[index],
          isCover: false
        };

        index1 = index;
      }

      if (item.path === image.path) {
        imagesArray[index] = {
          ...imagesArray[index],
          isCover: true
        };

        index2 = index;
      }
    });

    [imagesArray[index1], imagesArray[index2]] = [imagesArray[index2], imagesArray[index1]];

    setDefaultImages(imagesArray);
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success('Set images successfully');
      setShouldRefech(true);
    }
  }, [isSuccess]);

  return (
    <>
      <LoadingIndicator loading={isSetDefaultLoading || isUploadFileLoading} />
      <Box sx={{ pt: 2 }}>
        <Grid 
          container 
          spacing={3} 
          alignItems="center"
          justifyContent="center"
          sx={{ position: 'relative' }}
        >
          {images?.map((item) => {
            return item.isCover ? (
              <Grid
                item
                xs={12}
                className={classes.selectImage}
                key={item.path}
                container
                justifyContent="center"
              >
                <Grid item xs={4} className={classes.selectedImage} sx={{ minHeight: 100 }}>
                  <Box sx={{
                    p: 1,
                    background: '#E8E8E8',
                    position: 'relative'
                  }}>
                    <img 
                      src={item.url} 
                      alt=""
                    />
                    <IconButton
                      aria-label="delete"
                      className={clsx(classes.deleteIcon, 'md')}
                      onClick={() => handleDeleteImage(item)}
                    >
                      x
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid
                item xs={1} 
                key={item.path} 
                container 
                className={classes.smallImage}
              >
                <Box 
                  sx={{
                    p: 0.5,
                    background: '#E8E8E8',
                    position: 'relative'
                  }}
                  >
                    <IconButton
                      aria-label="delete"
                      className={classes.deleteIcon}
                      onClick={() => handleDeleteImage(item)}
                    >
                      x
                    </IconButton>
                    <Button
                      size='small'
                      className={classes.selectImageBtn}
                      onClick={() => handleSelectImage(item)}
                    >
                      Select
                    </Button>
                    <img src={item.url} alt="" />
                </Box>
              </Grid>
            );
          })}
          {images?.length < 5 ? (
            <Grid item xs={1}>
              <div className={classes.addImage}>
                <Tooltip
                  placement="bottom"
                  title="Add image"
                >
                  <label className={classes.uploadImage} htmlFor="file-path">
                    <AddIcon fontSize="medium" className={classes.addIcon} />
                    <TextField
                      className={classes.hidden}
                      id="file-path"
                      variant="outlined"
                      type="file"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: "image/*", hidden: true }}
                      onChange={(e) => {
                        handleUploadImage(e);
                      }}
                    />
                  </label>
                </Tooltip>
              </div>
            </Grid>
          ) : null}
          {
            !images.length && (
              <Typography 
                align="center"
                color="secondary"
                sx={{
                  fontStyle: 'italic',
                  fontSize: 16,
                  pl: 4
                }}
              >
                Default images has been empty
              </Typography>
            ) 
          }
          <Grid item sx={2} sx={{position: 'absolute', right: 40, bottom: 10}}>
            <Button 
              color="primary" 
              variant="contained"
              onClick={handleSaveImages}
              disabled={isSetDefaultLoading || !images.length}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

Images.propTypes = {
  images: PropTypes.instanceOf(Array),
  setDefaultImages: PropTypes.func,
  subId: PropTypes.string,
  setShouldRefech: PropTypes.func
}

Images.defaultProps = {
  images: null,
  setDefaultImages: () => {},
  subId: '',
  setShouldRefech: () => {}
}

export default Images;
