export const LOGIN_PATH = '/login';
export const HOME_PAGE_PATH = '/';
export const USERS_PAGE_PATH = '/users';
export const SETTING_PAGE_PATH = '/settings';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const ACCOUNT_PATH = '/account';
export const USER_LIST_PATH = '/user-list';
export const COMPANY_PATH = '/companies';
export const COMPANY_DETAIL_PATH = '/companies/:id';
export const CREATE_PASSWORD = '/create-password';
export const DONATE_EMAIL_PATH = '/donate-email';
export const DONATE_CENTER_PATH = '/donate-email/:id';
export const COMPANY_REGISTRATION_PATH = '/company-registration';
export const BRAND_PATH = '/brand';
export const SUB_CATEGORY_PATH = '/sub-category';
export const SUB_CATEGORY_DETAIL_PATH = '/sub-category/:id';
export const CURRENCY_PATH = '/currency';
