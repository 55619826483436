import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import baseConfig from 'config/base.config';
import { TOKEN } from 'constants/localStorageKeys';

export const baseQuery = fetchBaseQuery({
  baseUrl: baseConfig.serverUrl,
  prepareHeaders: (headers) => {
    const storageToken = localStorage.getItem(TOKEN);
    const accessToken = storageToken && JSON.parse(storageToken).accessToken;

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }

    return headers
  },
})
