import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Chip,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import PropTypes from 'prop-types';
import { COMPANY_STATUS, COMPANY_REQUEST_TYPE } from 'constants/companyStatus';
import { formatLongDate } from 'utils';

const CompanyConfidentialityInfor = ({ detailCompany,openEditCompanyInfo }) => {
  const renderStatus = () => {
    let text = '';
    let bgcolor = null;
    let color = 'white';
    if (detailCompany?.status === COMPANY_STATUS.APPROVED) {
      text = 'Approved';
      bgcolor = 'success.main';
    } else if (detailCompany?.status === COMPANY_STATUS.PENDING) {
      text = 'Pending';
      bgcolor = 'primary.main';
      color = 'white';
    } else if (detailCompany?.status === COMPANY_STATUS.REJECT) {
      text = 'Rejected';
      bgcolor = 'error.main';
    } else if (detailCompany?.status === COMPANY_STATUS.CANCELLED) {
      text = 'Cancelled';
    }
    if (bgcolor) {
      return (
        <Chip
          label={text}
          sx={{
            bgcolor,
            color,
          }}
        />
      );
    }
    return <Chip label={text} />;
  };

  const renderRequestType = () => {
    let text = '';
    let bgcolor = null;
    let color = 'white';
    if (detailCompany?.requestType === COMPANY_REQUEST_TYPE.NEW) {
      text = 'New';
      bgcolor = 'success.main';
    } else if (detailCompany?.requestType === COMPANY_REQUEST_TYPE.CHANGE) {
      text = 'Change';
      bgcolor = 'primary.main';
      color = 'white';
    }
    if (bgcolor) {
      return (
        <Chip
          label={text}
          sx={{
            bgcolor,
            color,
          }}
        />
      );
    }
    return <Chip label={text} />;
  };

  return (
    <Box>
      <Card elevation={0}>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={3} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Company Code"
                name="companyHouseRegistration"
                variant="outlined"
                defaultValue={detailCompany?.code}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Company House Registration (CRN)"
                name="companyHouseRegistration"
                variant="outlined"
                defaultValue={detailCompany?.newData?.companyHouseRegistration}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="VAT Number"
                name="VATNumber"
                variant="outlined"
                defaultValue={detailCompany?.newData?.VATNumber}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              {renderStatus()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Card elevation={0}>
        <CardHeader title="Request information" />
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Request by"
                name="email"
                variant="outlined"
                defaultValue={detailCompany?.user?.fullName}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Email"
                name="email"
                variant="outlined"
                defaultValue={detailCompany?.user?.email}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              {renderRequestType()}
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Request date"
                name="email"
                variant="outlined"
                defaultValue={formatLongDate(detailCompany?.createdAt, {
                  isMonthShort: true,
                })}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled
                fullWidth
                label="Verify date"
                name="email"
                variant="outlined"
                defaultValue={formatLongDate(detailCompany?.approvedAt, {
                  isMonthShort: true,
                })}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Card elevation={0}>
        <CardHeader title="Principal place of business" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={6}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Country"
                name="principalPlaceCountry"
                variant="outlined"
                defaultValue={
                  detailCompany?.newData?.principalPlaceCountry?.name
                }
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Region / State"
                name="principalPlaceState"
                variant="outlined"
                defaultValue={detailCompany?.newData?.principalPlaceState?.name}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="City"
                name="principalPlaceCity"
                variant="outlined"
                defaultValue={detailCompany?.newData?.principalPlaceCity?.name}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Post code"
                name="principalPlacePostCode"
                variant="outlined"
                defaultValue={detailCompany?.newData?.principalPlacePostCode}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Trading address"
                name="principalPlaceAddress"
                variant="outlined"
                multiline
                rows={3}
                defaultValue={detailCompany?.newData?.principalPlaceAddress}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Card elevation={0}>
        <CardHeader
          title="Registered address"
          action={
            <FormControlLabel
              label="Same as Principal Place of Business Trading Address"
              disabled
              labelPlacement="start"
              control={
                <Checkbox
                  defaultChecked={detailCompany?.newData?.isSamePrincipalPlace}
                />
              }
            />
          }
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={3} xs={6}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Country"
                name="registerCountry"
                variant="outlined"
                defaultValue={detailCompany?.newData?.registerCountry?.name}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Region / State"
                name="registerState"
                variant="outlined"
                defaultValue={detailCompany?.newData?.registerState?.name}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="City"
                name="registerCity"
                variant="outlined"
                defaultValue={detailCompany?.newData?.registerCity?.name}
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Post code"
                name="registerPostCode"
                variant="outlined"
                defaultValue={detailCompany?.newData?.registerPostCode}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                disabled={!openEditCompanyInfo}
                fullWidth
                label="Register address"
                name="registerAddress"
                variant="outlined"
                multiline
                rows={3}
                defaultValue={detailCompany?.newData?.registerAddress}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

CompanyConfidentialityInfor.propTypes = {
  detailCompany: PropTypes.instanceOf(Object),
};
CompanyConfidentialityInfor.defaultProps = {
  detailCompany: {},
};

export default CompanyConfidentialityInfor;
