const suffixKey = ['Address', 'City', 'State', 'Country'];

export const generateAddress = (prefix, data) => {
  if (!prefix) return '';
  const contentArr = suffixKey
    .map((key) => prefix + key)
    .map((key) => data[key])
    .filter((i) => i);
  if (contentArr.length === 0) return '';
  return contentArr.join(', ');
};
