import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import { ControlledTextfield } from 'components';
import PropTypes from 'prop-types';
import { useUpdateInfoMutation } from 'services/adminUserApi';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { formatLongDate } from 'utils/index';
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { useState } from 'react';
import { useMemo } from 'react';

const useStyles = makeStyles(() => ({
  phoneGroup: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
    '& .Mui-focused': {
      '& fieldset': {
        borderWidth: '1px !important',
        borderColor: 'rgba(0, 0, 0, 0.23) !important'
      }
    }
  },
  country: {
    '& fieldset': {
      borderRight: 0
    }
  },
  phoneInput: {
    '& fieldset': {
      borderLeft: 0
    },
  }
}));

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    phoneNumber: yup
      .string()
      .test("invalidPhone", "*Phone number is invalid", function (phoneNumber) {
        if (this.parent.country && phoneNumber) {
          if (!isValidPhoneNumber(phoneNumber, this.parent.country.countryCode)) {
            return false;
          }
        }
        return true;
      })
      .required('Phone number is required')
  })
  .required();

const AdminProfile = ({ adminProfile, setAdminProfile, countries }) => {
  const classes = useStyles();

  const initialCountry = useMemo(() => {
    if (adminProfile?.phoneNumber) {
      const phoneNumber = parsePhoneNumber(adminProfile?.phoneNumber);
      const { country } = phoneNumber
      return countries?.find((c) => c.countryCode === country);
    }
    return countries?.find((c) => c.countryCode === "GB");
  }, [adminProfile, countries]);

  const [country, setCountry] = useState(initialCountry);

  const [performUpdateInfo, { data: newInfo, isSuccess, error, isLoading }] =
    useUpdateInfoMutation();

  const getPhoneWithoutCountry = (value) => {
    const phone = value?.replace(`+${initialCountry?.phone}`, '');
    return phone || ' ';
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm({
    mode: 'all',
    defaultValues: {
      firstName: adminProfile?.firstName,
      lastName: adminProfile?.lastName,
      phoneNumber: getPhoneWithoutCountry(adminProfile?.phoneNumber),
    },
    resolver: yupResolver(schema),
  });

  const onUpdateProfile = async (data) => {
    const body = {
      ...data,
      phoneNumber: `+${country?.phone}${data.phoneNumber}`
    }
    performUpdateInfo(body);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setValue('country', e.target.value);
  }

  useEffect(() => {
    if (newInfo) {
      setAdminProfile(newInfo);
    }
  }, [newInfo]);

  useEffect(() => {
    if (isSuccess) {
      const message = 'Update information successfully !';
      toast.success(message);
    }
  }, [isSuccess]);

  useEffect(() => {
    setValue('country', initialCountry)
  }, [adminProfile])

  return (
    <Card elevation={0}>
      <CardHeader subheader="The information can be edited" title="Profile" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <ControlledTextfield
              fullWidth
              label="First name"
              name="firstName"
              variant="outlined"
              defaultValue={adminProfile?.firstName || ''}
              required
              control={control}
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ControlledTextfield
              fullWidth
              label="Last name"
              name="lastName"
              required
              variant="outlined"
              defaultValue={adminProfile?.lastName || ''}
              control={control}
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Email Address"
              name="email"
              value={adminProfile?.email || ''}
              variant="outlined"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid 
            item md={6} xs={12} 
            container 
            className={classes.phoneGroup}
          >
            <Grid item xs={3}>
              <PhoneInput
                className={classes.country}
                fullWidth
                countries={countries} 
                country={country || initialCountry}
                onChange={handleCountryChange}
              />
            </Grid>
            <Grid item xs={9}>
              <ControlledTextfield
                className={classes.phoneInput}
                fullWidth
                name="phoneNumber"
                defaultValues={getPhoneWithoutCountry(adminProfile?.phoneNumber)}
                variant="outlined"
                required
                control={control}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Status"
              name="status"
              disabled
              value={adminProfile?.status || ''}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Created time"
              name="createdTime"
              disabled
              value={
                formatLongDate(adminProfile?.createdAt, {
                  isMonthShort: true,
                }) || ''
              }
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit(onUpdateProfile)}
          disabled={isLoading}
        >
          {isLoading ? 'Saving' : 'Save details'}
        </Button>
      </Box>
    </Card>
  );
};

AdminProfile.propTypes = {
  adminProfile: PropTypes.objectOf(PropTypes.string),
  setAdminProfile: PropTypes.func,
  countries: PropTypes.instanceOf(Array)
};

AdminProfile.defaultProps = {
  setAdminProfile: () => {},
  adminProfile: {},
  countries: null
};

export default AdminProfile;
