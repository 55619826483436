/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormDialog, Table, ControlledTextfield } from 'components';
import {
  useGetEmailsListQuery,
  useAddEmailsMutation,
  useDeleteEmailMutation,
} from 'services/donateEmailsApi';
import {
  IconButton,
  Tooltip,
  Typography,
  Button,
  Grid,
  Paper,
  Chip,
  ListItem,
  Divider,
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const schema = yup
  .object({
    email: yup
      .string()
      .email('Email must be a valid email')
      .required('Email is required'),
  })
  .required();

const DonationCenterEmails = ({ donateId }) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAddEmailDialog, setOpenAddEmailDialog] = useState(false);
  const [deletedEmail, setDeletedEmail] = useState('');
  const [addedEmails, setAddedEmails] = useState([]);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const [
    performAddEmails,
    { isSuccess: isAddSuccess, isLoading: isAddLoading },
  ] = useAddEmailsMutation();

  const [
    performDeleteEmail,
    { isSuccess: isDeleteSuccess, isLoading: isDeleteLoading },
  ] = useDeleteEmailMutation();

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const handleDeleteEmail = (row) => {
    setOpenConfirmDialog(true);
    setDeletedEmail(row?.email);
  };

  const handleAddEmail = (data) => {
    const { email } = data;
    const emailIndex = addedEmails.indexOf(email);

    if (emailIndex < 0) {
      setAddedEmails((prevState) => [...prevState, email]);
    }
    reset({
      email: '',
    });
  };

  const handleSaveEmailList = () => {
    performAddEmails({
      emails: addedEmails,
      donateId,
    });
  };

  const handleDeleteChip = (item) => {
    const newAddedEmails = [...addedEmails].filter((email) => email !== item);
    setAddedEmails(newAddedEmails);
  };

  const handleCloseAddDialog = () => {
    setOpenAddEmailDialog(false);
  };

  useEffect(() => {
    if (isAddSuccess) {
      toast.success('Add emails successfully');
      setOpenAddEmailDialog(false);
      setAddedEmails([]);
      setShouldRefetch(true);
    }
  }, [isAddSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success('Delete successfully');
      setOpenConfirmDialog(false);
      setShouldRefetch(true);
    }
  }, [isDeleteSuccess]);

  const columns = [
    {
      id: 'email',
      label: 'Email',
    },
    {
      id: 'action',
      label: 'Action',
      align: 'right',
      // eslint-disable-next-line react/display-name
      content: (row) => (
        <Tooltip title="Delete" arrow placement="top">
          <IconButton onClick={() => handleDeleteEmail(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <FormDialog
        open={openConfirmDialog}
        handleClose={() => setOpenConfirmDialog(false)}
        title="Are you sure ?"
        buttons={[
          {
            onClick: () => setOpenConfirmDialog(false),
            label: 'Discard',
            color: 'secondary',
          },
          {
            onClick: () =>
              performDeleteEmail({
                email: deletedEmail,
                donateId,
              }),
            color: 'primary',
            label: 'Delete',
            disabled: isDeleteLoading,
          },
        ]}
        maxWidth="xs"
        fullWidth
      >
        <Typography gutterBottom>Are you want to delete this email</Typography>
      </FormDialog>
      <FormDialog
        open={openAddEmailDialog}
        onClose={handleCloseAddDialog}
        buttons={[
          {
            onClick: () => setOpenAddEmailDialog(false),
            label: 'Cancel',
            styles: { marginRight: '8px' },
          },
          {
            onClick: () => handleSaveEmailList(),
            label: 'Save',
            variant: 'contained',
            disabled: isAddLoading || !addedEmails.length,
          },
        ]}
        title="Add new email"
      >
        <Grid
          container
          sx={{
            minWidth: 600,
            alignItems: 'center',
          }}
          columnSpacing={2}
        >
          <Grid item xs={9}>
            <ControlledTextfield
              control={control}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              error={!!errors.email}
              helperText={errors.email?.message}
              sx={{ marginBottom: '16px' }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit(handleAddEmail)}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        {addedEmails.length > 0 && (
          <>
            <Divider />
            <Typography sx={{ py: 1, fontWeight: 600 }}>
              Added Emails
            </Typography>
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                listStyle: 'none',
                maxHeight: 300,
                overflow: 'auto',
              }}
            >
              {addedEmails?.map((email, index) => (
                <ListItem
                  disableGutters
                  key={index}
                  sx={{
                    width: 'fit-content',
                    pr: 1,
                  }}
                >
                  <Chip
                    label={email}
                    onDelete={() => handleDeleteChip(email)}
                  />
                </ListItem>
              ))}
            </Paper>
            <Divider sx={{ pt: 2 }} />
          </>
        )}
      </FormDialog>
      <Table
        useHookApi={useGetEmailsListQuery}
        title="Donation emails"
        columns={columns}
        otherQueryParams={{ donateId }}
        shouldRefetch={shouldRefetch}
        setShouldRefetch={setShouldRefetch}
      >
        <Button
          onClick={() => setOpenAddEmailDialog(true)}
          startIcon={<AddIcon />}
          variant="contained"
        >
          Add new email
        </Button>
      </Table>
    </>
  );
};

DonationCenterEmails.propTypes = {
  donateId: PropTypes.string,
};
DonationCenterEmails.defaultProps = {
  donateId: '',
};

export default DonationCenterEmails;
