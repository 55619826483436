import { createApi } from '@reduxjs/toolkit/query/react';
import { TOKEN } from 'constants/localStorageKeys';
import { baseQuery } from './baseQuery';

export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: '/api/admin/auth/login',
        method: 'POST',
        body: { email, password },
      }),
      transformResponse: (response) => {
        const token = JSON.stringify({
          accessToken: response.accessToken.token,
          refreshToken: response.refreshToken.token,
        });
        localStorage.setItem(TOKEN, token);
        return response;
      },
    }),
    resetPassword: builder.mutation({
      query: ({ email }) => ({
        url: '/api/admin/auth/reset-password',
        method: 'POST',
        body: { email },
      }),
      transformResponse: (response) => response,
    }),
    verifyToken: builder.mutation({
      query: ({ token }) => ({
        url: '/api/admin/auth/verify-token',
        method: 'POST',
        body: { token },
      }),
      transformResponse: (response) => response,
    }),
    renewalPassword: builder.mutation({
      query: ({ token, password }) => ({
        url: '/api/admin/auth/renewal-password',
        method: 'POST',
        body: { token, password },
      }),
      transformResponse: (response) => response,
    }),
    verifyActiveCode: builder.mutation({
      query: ({ activeCode }) => ({
        url: '/api/admin/auth/verify-active-code',
        method: 'POST',
        body: { activeCode },
      }),
      transformResponse: (response) => response,
    }),
    activeAccount: builder.mutation({
      query: ({ activeCode, password, firstName, lastName }) => ({
        url: '/api/admin/auth/active-account',
        method: 'POST',
        body: { activeCode, password, firstName, lastName },
      }),
      transformResponse: (response) => response,
    }),
  }),
});

export const {
  useLoginMutation,
  useResetPasswordMutation,
  useVerifyTokenMutation,
  useRenewalPasswordMutation,
  useVerifyActiveCodeMutation,
  useActiveAccountMutation,
} = authenticationApi;
