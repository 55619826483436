/*eslint-disable*/
import axios from "axios";
import baseConfig from "config/base.config";
import { TOKEN } from 'constants/localStorageKeys';

const getSignedUrl = (data, type) => {
  const storageToken = localStorage.getItem(TOKEN);
  const accessToken = storageToken && JSON.parse(storageToken).accessToken;
  const config = {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  }

  return axios.post(`${baseConfig.serverUrl}/api/sign-url`, {
    fileName: data.name,
    contentType: data.type,
    type: type,
  }, config)
    .then(({ data, status }) => {
      if (status === 200) return data;
      else return {};
    })
}

export const uploadFile = async (data, type = "company") => {
  try {
    let { signedUrl, path, errors } = await getSignedUrl(data, type);

    if (errors) throw errors[0];
    if (!signedUrl) return;

    const file = new File([data], data.name);
    let result = await axios.put(signedUrl, file, {
      headers: {
        "Content-Type": data.type || "image/png",
      },
    });
    if (result?.status === 200)
      return {
        name: data.name,
        size: data.size,
        path,
      };
  } catch (error) {
    console.log(error.response || error);
    throw error.errors ? error.errors[0] || error : error.response || error;
  }
};
