import { isRejectedWithValue } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

/**
 * Log a warning and show a toast!
 * https://redux-toolkit.js.org/rtk-query/usage/error-handling
 */
const errorHandler = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const message =
      action?.payload?.data?.message ||
      action?.payload?.data?.errors[0].message;
    console.error(message);
    toast.error(message);
  }

  return next(action);
};

export default errorHandler;
