import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  InputAdornment,
  TextField,
  LinearProgress,
  Box,
} from '@mui/material';
import {
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  SwapVert as SwapVertIcon,
} from '@mui/icons-material';
import PropTypes from 'prop-types';

const Table = (props) => {
  const {
    title,
    useHookApi,
    columns,
    onItemCreated,
    isLoadingData,
    children,
    renderFilter,
    statusFilter,
    roleFilter,
    shouldRefetch,
    setShouldRefetch,
    otherQueryParams,
    handleData,
    order,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSearch(event?.target?.search?.value || event?.target?.value || '');
  };

  // MUI use page number from 0, and BE use page number from 1, so we need to increase BE before sending to BE
  const { data, isLoading, refetch, isFetching } = useHookApi({
    page: page + 1,
    keyword: search,
    limit: rowsPerPage,
    status: statusFilter,
    type: roleFilter,
    ...otherQueryParams,
    order,
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (onItemCreated) {
      onItemCreated({
        page: page + 1,
        keyword: search,
        limit: rowsPerPage,
        status: statusFilter,
        type: roleFilter,
        ...otherQueryParams,
        order,
      });
    }
  }, [onItemCreated]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      if (setShouldRefetch) {
        setShouldRefetch(false);
      }
    }
  }, [shouldRefetch]);

  useEffect(() => {
    if (data) {
      handleData(data);
    }
  }, [data]);

  const searchBar = () => (
    <Paper
      component="form"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        mb: 1,
      }}
      onSubmit={handleSearch}
      elevation={0}
    >
      <TextField
        id="outlined-basic"
        variant="outlined"
        name="search"
        placeholder="Search"
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            handleSearch(ev);
          }
        }}
        sx={{
          minWidth: 350,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {children}
    </Paper>
  );

  return (
    <>
      <h2 style={{ marginTop: 0 }}>{title}</h2>
      <Box display="flex">
        {searchBar()}
        {renderFilter && renderFilter()}
      </Box>
      {isLoading || isFetching || isLoadingData ? (
        <LinearProgress />
      ) : (
        <Box height="4px" />
      )}
      <Paper sx={{ width: '100%', overflow: 'hidden', mt: 1 }} elevation={2}>
        <TableContainer>
          <MUITable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={`id-${column.id}`}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: 'bold',
                      fontSize: 14,
                      ...column?.customStyle,
                    }}
                  >
                    {column.label}
                    {column.onSort ? (
                      <>
                        {order.includes(column.sortKey) ? (
                          <>
                            {order.includes('-') ? (
                              <ArrowDropDownIcon
                                onClick={() => column.onSort('asc')}
                                sx={{
                                  cursor: 'pointer',
                                  transform: 'translateY(8px)',
                                }}
                              />
                            ) : (
                              <ArrowDropUpIcon
                                onClick={() => column.onSort('desc')}
                                sx={{
                                  cursor: 'pointer',
                                  transform: 'translateY(8px)',
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <SwapVertIcon
                            onClick={() => column.onSort('asc')}
                            sx={{
                              cursor: 'pointer',
                              color: 'gray',
                              transform: 'translateY(6px)',
                            }}
                          />
                        )}
                      </>
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.items.map((item, itemIndex) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={item._id || item.id || itemIndex}
                  >
                    {columns.map((column, index) => {
                      const value = column.content
                        ? column.content(item, index)
                        : item[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            fontSize: 14,
                            cursor: 'pointer',
                            ...column?.customStyle,
                          }}
                          onClick={
                            column.onClick ? () => column.onClick(item) : null
                          }
                        >
                          {column.handleShowText
                            ? column.handleShowText(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </MUITable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data ? data.metaData.total : 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

Table.propTypes = {
  title: PropTypes.string,
  useHookApi: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
  onItemCreated: PropTypes.func,
  isLoadingData: PropTypes.bool,
  renderFilter: PropTypes.func,
  statusFilter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  roleFilter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  shouldRefetch: PropTypes.bool,
  setShouldRefetch: PropTypes.func,
  otherQueryParams: PropTypes.shape({}),
  handleData: PropTypes.func,
  order: PropTypes.string,
};

Table.defaultProps = {
  title: '',
  useHookApi: () => {},
  columns: [],
  onItemCreated: () => {},
  isLoadingData: false,
  renderFilter: () => {},
  statusFilter: null,
  roleFilter: null,
  children: null,
  shouldRefetch: false,
  setShouldRefetch: () => {},
  otherQueryParams: {},
  handleData: () => {},
  order: '',
};

export default Table;
