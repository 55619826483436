
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './baseQueryWithReAuth';

export const infoApi = createApi({
  reducerPath: 'infoApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getProductTypes: builder.query({
      query: () => ({
        url: 'api/product-types'
      }),
      transformResponse: (response) => response,
    }),
    setDefaultImages: builder.mutation({
      query: ({ subId, images}) => ({
        url: `/api/sub-product-types/${subId}/images`,
        method: 'PUT',
        body: { images }
      }),
      transformResponse: (response) => response,
    }),
    getCountries: builder.query({
      query: () => ({
        url: `api/countries?continent=EU`
      }),
      transformResponse: (response) => response.items,
    }),
    getCurrencies: builder.query({
      query: () => ({
        url: 'api/currencies'
      }),
      transformResponse: (response) => response,
    }),
    toggleCurrencyStatus: builder.mutation({
      query: ({ currencyId }) => ({
        url: `/api/currencies/${currencyId}/toggle-status`,
        method: 'PUT',
      }),
      transformResponse: (response) => response,
    }),
  }),
});

export const {
  useGetProductTypesQuery,
  useSetDefaultImagesMutation,
  useGetCountriesQuery,
  useGetCurrenciesQuery,
  useToggleCurrencyStatusMutation
} = infoApi;
