export const USER_STATUS = [
  {
    value: 0,
    name: 'All status',
  },
  {
    value: 'BLOCKED',
    name: 'Blocked',
  },
  {
    value: 'ACTIVATED',
    name: 'Activated',
  },
  {
    value: 'INACTIVATED',
    name: 'Inactivated',
  },
];

export const USER_STATUS_CONSTANT = {
  INACTIVATED: 'INACTIVATED',
  ACTIVATED: 'ACTIVATED',
  BLOCKED: 'BLOCKED',
};
