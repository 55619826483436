/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  useListCompaniesQuery,
  useListEmployeeCompanyQuery,
  usePromoteCompanyAdminMutation,
  useUpdateCompanyLocationMutation,
} from 'services/companyApi';
import { COMPANY_STATUS, COMPANY_STATUS_LABEL } from 'constants/companyStatus';
import { USER_STATUS_CONSTANT } from 'constants/userStatus';
import { USER_ROLES_CONSTANT } from 'constants/userRole';

import { Table, FruproPage, FormDialog } from 'components';
import { IconButton, Chip, Tooltip, Checkbox, Button } from '@mui/material';
import {
  RemoveRedEye as RemoveRedEyeIcon,
  PeopleAlt as PeopleAltIcon,
  Save as SaveIcon,
  EditLocation as EditLocationIcon,
} from '@mui/icons-material';

import { showToastMessage } from 'utils/toastMessage';
import { ACTION } from 'constants/action';

import UpdateLocation from './UpdateLocation';

const Companies = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const [listEmployees, setListEmployees] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [companyID, setCompanyID] = useState('');
  const [company, setCompany] = useState({});

  const [promoteUserIds, setPromoteUserIds] = useState([]);
  const [degradeUserIds, setDegradeUserIds] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [defaultCheckList, setDefaultCheckList] = useState([]);

  const [openModalUpdateLocation, setOpenModalUpdateLocation] = useState(false);
  const [locationInfo, setLocationInfo] = useState();

  const [performPromoteCompanyAdmin, { isSuccess: isPromoteSuccess }] =
    usePromoteCompanyAdminMutation();

  const [
    performUpdateCompanyLocation,
    { isSuccess: isUpdateCompanyLocationSuccess },
  ] = useUpdateCompanyLocationMutation();

  const [order, setOrder] = useState('');

  const handleOpenModal = (row) => {
    setCompanyID(row.id);
    setCompany(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setListEmployees(undefined);
    setDefaultCheckList([]);
    setCheckedList([]);
    setPromoteUserIds([]);
    setDegradeUserIds([]);
    setOpenModal(false);
  };

  const handleSavePromote = () => {
    const newSelectedDegrate = defaultCheckList.filter(
      (x) => !checkedList.includes(x)
    );
    const newUserIdsDegrate = newSelectedDegrate.filter(
      (x) => !degradeUserIds.includes(x)
    );
    const newUserIdsPromote = checkedList.filter(
      (x) => !promoteUserIds.includes(x)
    );

    let data = {
      companyId: companyID,
      degradeUserIds: newUserIdsDegrate,
      promoteUserIds: newUserIdsPromote,
    };
    if (newUserIdsPromote.length)
      data = { companyId: companyID, promoteUserIds: newUserIdsPromote };
    if (newUserIdsDegrate.length)
      data = { companyId: companyID, degradeUserIds: newUserIdsDegrate };
    performPromoteCompanyAdmin(data);
  };

  const isSelected = (id) => checkedList.indexOf(id) !== -1;

  const handleCheckboxClick = (event, id) => {
    event.stopPropagation();
    const selectedIndexPromote = checkedList.indexOf(id);
    let newSelectedPromote = [];

    if (selectedIndexPromote === -1) {
      newSelectedPromote = newSelectedPromote.concat(checkedList, id);
    } else if (selectedIndexPromote === 0) {
      newSelectedPromote = newSelectedPromote.concat(checkedList.slice(1));
    } else if (selectedIndexPromote === checkedList.length - 1) {
      newSelectedPromote = newSelectedPromote.concat(checkedList.slice(0, -1));
    } else if (selectedIndexPromote > 0) {
      newSelectedPromote = newSelectedPromote.concat(
        checkedList.slice(0, selectedIndexPromote),
        checkedList.slice(selectedIndexPromote + 1)
      );
    }
    setCheckedList(newSelectedPromote);
  };

  const columnsDetailCompany = [
    {
      id: 'companyName',
      label: 'Company name',
      minWidth: 180,
      onSort: (order) => {
        if (order === 'asc') {
          setOrder('companyName');
        } else {
          setOrder('-companyName');
        }
      },
      sortKey: 'companyName',
    },
    {
      id: 'companyType',
      label: 'Company type',
      minWidth: 180,
      onSort: (order) => {
        if (order === 'asc') {
          setOrder('companyType');
        } else {
          setOrder('-companyType');
        }
      },
      sortKey: 'companyType',
    },
    {
      id: 'companyHouseRegistration',
      label: 'Registration house number',
      minWidth: 170,
    },
    {
      id: 'VATNumber',
      label: 'VAT number',
      minWidth: 180,
      onSort: (order) => {
        if (order === 'asc') {
          setOrder('VATNumber');
        } else {
          setOrder('-VATNumber');
        }
      },
      sortKey: 'VATNumber',
    },
    {
      id: 'description',
      label: 'Description',
      minWidth: 100,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 150,
      content: (row) => {
        let text = '';
        let bgcolor = null;
        const color = 'white';

        switch (row.status) {
          case COMPANY_STATUS.APPROVED:
            text = COMPANY_STATUS_LABEL.APPROVED;
            bgcolor = 'success.main';
            break;
          case COMPANY_STATUS.PENDING:
            text = COMPANY_STATUS_LABEL.PENDING;
            break;
          case COMPANY_STATUS.VERIFIED:
            text = COMPANY_STATUS_LABEL.VERIFIED;
            bgcolor = '#1976d2';
            break;
          default:
            break;
        }

        if (bgcolor) {
          return (
            <Chip
              label={text}
              sx={{
                bgcolor,
                color,
              }}
            />
          );
        }
        return <Chip label={text} />;
      },
      onSort: (order) => {
        if (order === 'asc') {
          setOrder('status');
        } else {
          setOrder('-status');
        }
      },
      sortKey: 'status',
    },
    {
      id: 'companyAdmin',
      label: 'Company admin',
      minWidth: 120,
      content: (row) => <span>{row?.user?.fullName}</span>,
    },
    {
      id: 'totalMember',
      label: 'Number of employee',
      minWidth: 200,
      onSort: (order) => {
        if (order === 'asc') {
          setOrder('totalMember');
        } else {
          setOrder('-totalMember');
        }
      },
      sortKey: 'totalMember',
    },
    {
      id: 'actions',
      label: '',
      minWidth: 125,
      content: (row) => (
        <>
          <Tooltip title="View employee">
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleOpenModal(row, false);
              }}
            >
              <PeopleAltIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="View detail">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push({
                  pathname: `${path}/${row?.id}`,
                  state: row,
                });
              }}
            >
              <RemoveRedEyeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Update location">
            <IconButton
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpenModalUpdateLocation(true);
                setLocationInfo({
                  companyId: row?.id,
                  location: row?.location,
                });
              }}
            >
              <EditLocationIcon sx={{ fontSize: '28px' }} />
            </IconButton>
          </Tooltip>
        </>
      ),
      customStyle: {
        position: 'sticky',
        right: 0,
        background: 'white',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
      },
    },
  ];

  const columnsEmployeeCompany = [
    { id: 'fullName', label: 'Full name', minWidth: 100 },
    { id: 'email', label: 'Email', minWidth: 100 },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      content: (row) => {
        let text = '';
        let bgcolor = null;
        let color = 'white';

        if (row.status === USER_STATUS_CONSTANT.ACTIVATED) {
          text = 'Active';
          bgcolor = 'success.main';
        }
        if (row.status === USER_STATUS_CONSTANT.INACTIVATED) {
          text = 'Inactive';
          bgcolor = 'secondary.main';
          color = 'black';
        }
        if (row.status === USER_STATUS_CONSTANT.BLOCKED) {
          text = 'Block';
          bgcolor = 'error.main';
        }
        if (bgcolor) {
          return (
            <Chip
              label={text}
              sx={{
                bgcolor,
                color,
              }}
            />
          );
        }
        return <Chip label={text} />;
      },
    },
    {
      id: 'actions',
      label: 'Is company administrator',
      content: (row) => (
        <Checkbox
          onChange={(event) => handleCheckboxClick(event, row._id || row.id)}
          checked={isSelected(row._id || row.id)}
        />
      ),
    },
  ];

  useEffect(() => {
    if (listEmployees) {
      const promoteIdsDefault = [];
      const degradeIdsDefault = [];

      const checkList = listEmployees.items.map((d) => d._id || d.id);
      const defaultCheckedList = listEmployees.items
        .filter((d) => d.type === USER_ROLES_CONSTANT.COMPANY_ADMIN)
        .map((d) => d._id || d.id);

      listEmployees.items.map((d) =>
        d.type === USER_ROLES_CONSTANT.COMPANY_ADMIN
          ? promoteIdsDefault.push(d._id || d.id)
          : degradeIdsDefault.push(d._id || d.id)
      );

      setDefaultCheckList(checkList);
      setCheckedList(defaultCheckedList);
      setPromoteUserIds(promoteIdsDefault);
      setDegradeUserIds(degradeIdsDefault);
    }
  }, [listEmployees]);

  useEffect(() => {
    if (isPromoteSuccess) {
      let userName = '';

      if (checkedList?.length > 1) {
        userName = `${checkedList.length} users`
      } else {
        const user = listEmployees?.items?.find((item) => (item._id || item.id) === checkedList[0]);
        userName = user.fullName;
      };

      showToastMessage({
        userName,
        companyName: company?.companyName,
        action: ACTION.PROMOTE_ADMIN
      });
    }
  }, [isPromoteSuccess]);

  useEffect(() => {
    if (isUpdateCompanyLocationSuccess) {
      toast.success('Update company location successfully', {
        toastId: 'update-company-location-id',
      });
    }
  }, [isUpdateCompanyLocationSuccess]);

  return (
    <FruproPage>
      <Table
        columns={columnsDetailCompany}
        title="Companies list"
        useHookApi={useListCompaniesQuery}
        order={order}
      />

      <FormDialog
        maxWidth="md"
        fullWidth
        open={openModal}
        onClose={handleCloseModal}
        buttons={[
          {
            onClick: handleCloseModal,
            label: 'Cancel',
          },
        ]}
      >
        <Table
          columns={columnsEmployeeCompany}
          title="Employees list"
          otherQueryParams={{ companyId: companyID }}
          useHookApi={useListEmployeeCompanyQuery}
          handleData={setListEmployees}
          shouldRefetch={isPromoteSuccess || isUpdateCompanyLocationSuccess}
        >
          <Button
            onClick={handleSavePromote}
            startIcon={<SaveIcon />}
            variant="contained"
          >
            Save
          </Button>
        </Table>
      </FormDialog>
      {locationInfo && openModalUpdateLocation ? (
        <UpdateLocation
          open={openModalUpdateLocation}
          setOpen={setOpenModalUpdateLocation}
          locationInfo={locationInfo}
          performUpdate={performUpdateCompanyLocation}
          isSuccess={isUpdateCompanyLocationSuccess}
        />
      ) : null}
    </FruproPage>
  );
};

Companies.propTypes = {};

export default Companies;
