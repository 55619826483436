import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { HOME_PAGE_PATH } from 'constants/paths';
import { TOKEN } from 'constants/localStorageKeys';

const PublicRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem(TOKEN);

  if (!token) {
    return <Route component={Component} {...rest} />;
  }

  return <Redirect to={HOME_PAGE_PATH} />;
};

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PublicRoute;
