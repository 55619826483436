import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { LockOutlined, Telegram as TelegramIcon } from '@mui/icons-material';
import {
  Avatar,
  Button,
  CssBaseline,
  Link as MaterialLink,
  Box,
  Typography,
  Container,
} from '@mui/material';
import { ControlledTextfield } from 'components';
import { useResetPasswordMutation } from 'services/authenticationApi';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { LOGIN_PATH } from 'constants/paths';

const schema = yup
  .object({
    email: yup
      .string()
      .email('Email must be a valid email')
      .required('Email is required'),
  })
  .required();

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <MaterialLink color="inherit" href="http://localhost:3000">
        FruPro
      </MaterialLink>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const customId = 'toast-forgot-id';

export default function ForgotPassword() {
  const [performResetPassword, { isSuccess, error, isLoading }] =
    useResetPasswordMutation();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const handleResetPassword = (data) => {
    performResetPassword(data);
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      const message = 'We have sent reset link to your email';
      toast.success(message, { toastId: customId });
    }
  }, [error, isSuccess]);

  if (isSuccess) {
    return <Redirect to={LOGIN_PATH} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Typography
          variant="body1"
          sx={{
            pt: 2,
          }}
        >
          Lost your password? Please enter your email address. You will receive
          a link to create a new password via email.
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(handleResetPassword)}
          noValidate
          sx={{
            mt: 1,
            width: '100%',
          }}
        >
          <ControlledTextfield
            control={control}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            color="primary"
            disabled={isLoading}
          >
            <TelegramIcon />
            {isLoading ? 'Sending' : 'Send'}
          </Button>
        </Box>
        <MaterialLink href={LOGIN_PATH}>Back to Sign in</MaterialLink>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
