/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  Chip,
  Link,
  Button,
} from '@mui/material';
import {
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
} from '@mui/icons-material';
import {
  useBlockUsersMutation,
  useGetUserListQuery,
  useUnblockUsersMutation,
} from 'services/adminUserApi';
import {
  Dialog,
  FruproPage,
  Table,
  FormDialog,
  ControlledTextfield,
} from 'components';
import { formatLongDate } from 'utils';
import { USERS_TABLE } from 'constants/tableType';
import { toast } from 'react-toastify';
import { ADMIN_USER_STATUS } from 'constants/adminUserStatus';
import { USER_STATUS } from 'constants/userStatus';
import { USER_ROLE } from 'constants/userRole';
import { makeStyles } from '@mui/styles';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import baseConfig from 'config/base.config';
import { useImpersonateMutation } from 'services/impersonation';
import LoadingIndicator from 'components/LoadingIndicator';

const USER_DETAILS_LIST = [
  { key: 'fullName', label: 'Full name' },
  { key: 'email', label: 'Email' },
  { key: 'registerAddress', label: 'Address' },
  { key: 'phoneNumber', label: 'Mobile phone' },
  { key: 'companyType', label: 'Company type' },
  { key: 'type', label: 'Company role' },
  { key: 'status', label: 'Status' },
  { key: 'createdAt', label: 'Created time' },
  { key: 'country', label: 'Country' },
  { key: 'speciality', label: 'Speciality' },
  { key: 'companyName', label: 'Company name' },
  { key: 'companyType', label: 'Company type' },
];

const COMPANY_DETAILS_LIST = [
  { key: 'companyName', label: 'Company name' },
  { key: 'companyType', label: 'Company type' },
  { key: 'companyForm', label: 'Company form' },
  { key: 'companyHouseRegistration', label: 'Registration house number' },
  { key: 'VATNumber', label: 'VAT Number' },
  { key: 'registerAddress', label: 'Registered address' },
  { key: 'companyStatus', label: 'Status' },
];

const useStyles = makeStyles(() => ({
  statusControl: {
    width: '12%',
    '& .MuiFilledInput-root': {
      padding: 0,
      borderRadius: 0,
    },
    '& .MuiSelect-select': {
      paddingTop: 10,
    },
  },
}));

const UserList = () => {
  const classes = useStyles();
  const history = useHistory();

  const [detailsList, setdetailsList] = useState([]);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openBlockDialog, setOpenBlockDialog] = useState(false);
  const [userIds, setUserIds] = useState([]);

  const [statusFilter, setStatusFilter] = useState(0);
  const [roleFilter, setRoleFilter] = useState(0);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [order, setOrder] = useState('');

  const [performBlockUsers, { isSuccess: isBlockSuccess }] =
    useBlockUsersMutation();

  const [performUnblockUsers, { isSuccess: isUnblockSuccess }] =
    useUnblockUsersMutation();

  const [
    performImpersonate,
    {
      data: impersonationData,
      isSuccess: isImpersonateSuccess,
      isLoading: isImpersonateLoading,
    },
  ] = useImpersonateMutation();

  const schema = yup
    .object({
      blockReason: yup.string().required('Block reason is required'),
    })
    .required();

  const {
    control,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm({ mode: 'all', resolver: yupResolver(schema) });

  const handleCloseForm = () => {
    clearErrors();
    reset({ blockReason: '' });
    setOpenBlockDialog(false);
  };

  const handleOpenDetailsModal = (row, columnId) => {
    let tempDetailsList = [];

    const transformedRow = {
      ...row,
      createdAt: formatLongDate(row?.createdAt, { isMonthShort: true }),
      country: row?.country?.name,
      speciality: row?.company?.speciality,
      companyName: row?.company?.companyName,
      companyType: row?.company?.companyTypeOther || row?.company?.companyType,
      companyForm: row?.company?.companyForm,
      companyStatus: row?.company?.status,
      companyHouseRegistration: row?.company?.companyHouseRegistration,
      VATNumber: row?.company?.VATNumber,
    };

    const newListDetails = [];

    if (columnId === 'fullName') {
      tempDetailsList = USER_DETAILS_LIST;
      setDialogTitle('User profile');
    } else {
      tempDetailsList = COMPANY_DETAILS_LIST;
      setDialogTitle('Company profile');
    }

    Object.entries(transformedRow).forEach(([key, value]) => {
      const newRow = tempDetailsList.find((d) => d.key === key);
      if (newRow) newListDetails.push({ ...newRow, value });
    });

    setdetailsList(newListDetails);
    setOpenDetailsModal(true);
  };

  const handleBlockUsers = (formData) => {
    performBlockUsers({
      blockReason: formData.blockReason,
      userIds,
    });
    reset({ blockReason: '' });
    setOpenBlockDialog(false);
  };

  const handleUnblockUsers = () => {
    performUnblockUsers({ userIds });
    setOpenConfirmDialog(false);
  };

  useEffect(() => {
    if (isBlockSuccess) {
      setOpenConfirmDialog(false);
      toast.success('Block successfully');
      setShouldRefetch(true);
    }
  }, [isBlockSuccess]);

  useEffect(() => {
    if (isUnblockSuccess) {
      toast.success('Unblock successfully');
      setShouldRefetch(true);
    }
  }, [isUnblockSuccess]);

  useEffect(() => {
    if (isImpersonateSuccess) {
      const { signature } = impersonationData || {};
      window.open(
        `${baseConfig.marketplaceUrl}/impersonation?signature=${signature}`,
        '_blank'
      );

      console.log(isImpersonateSuccess);
    }
  }, [isImpersonateSuccess]);

  const handleFilterChange = (e, filterType) => {
    const filterValue = e.target.value;

    if (filterType === 'status') {
      setStatusFilter(filterValue);
    } else {
      setRoleFilter(filterValue);
    }
  };

  const onImpersonate = (row) => {
    performImpersonate({
      userId: row?.id,
    });
  };

  const renderFilter = () => (
    <>
      <FormControl
        variant="filled"
        className={classes.statusControl}
        sx={{
          px: 6,
        }}
      >
        <Select
          onChange={(e) => handleFilterChange(e, 'status')}
          value={statusFilter}
          disableUnderline
        >
          {USER_STATUS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="filled" className={classes.statusControl}>
        <Select
          onChange={(e) => handleFilterChange(e, 'role')}
          value={roleFilter}
          disableUnderline
        >
          {USER_ROLE.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

  const renderStatus = (status) => {
    let color = '';
    let label = '';
    let textColor = 'white';

    switch (status) {
      case 'ACTIVATED':
        color = 'success';
        label = 'Activated';
        break;
      case 'INACTIVATED':
        color = 'secondary';
        label = 'Inactivated';
        textColor = 'black';
        break;
      default:
        color = 'error';
        label = 'Blocked';
        break;
    }

    return (
      <Chip
        color={color}
        label={label}
        sx={{
          color: textColor,
        }}
      />
    );
  };

  const columns = [
    {
      id: 'fullName',
      label: 'Full name',
      content: (row) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          component="button"
          variant="body2"
          onClick={() => handleOpenDetailsModal(row, 'fullName')}
          sx={{ fontSize: '14px' }}
        >
          {row?.fullName}
        </Link>
      ),
    },
    { id: 'email', label: 'Email' },
    {
      id: 'registerAddress',
      label: 'Address',
      content: (row) =>
        row?.company?.registerAddress || row?.company?.principalPlaceAddress,
    },
    {
      id: 'country',
      label: 'Country',
      content: (row) => row?.country?.name,
    },
    { id: 'phoneNumber', label: 'Mobile phone' },
    {
      id: 'speciality',
      label: 'Speciality',
      content: (row) => row?.company?.speciality,
      handleShowText: (value) => value || '--',
    },
    {
      id: 'companyName',
      label: 'Company name',
      content: (row) =>
        row?.company?.companyName ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            component="button"
            variant="body2"
            onClick={() => handleOpenDetailsModal(row, 'companyName')}
            sx={{ fontSize: '14px' }}
          >
            {row?.company?.companyName}
          </Link>
        ) : (
          '--'
        ),
      onSort: (order) => {
        if (order === 'asc') {
          setOrder('companyName');
        } else {
          setOrder('-companyName');
        }
      },
      sortKey: 'companyName',
      minWidth: 180,
    },
    {
      id: 'companyType',
      label: 'Company type',
      content: (row) =>
        row?.company?.companyTypeOther || row?.company?.companyType,
      handleShowText: (value) => value || '--',
      onSort: (order) => {
        if (order === 'asc') {
          setOrder('companyType');
        } else {
          setOrder('-companyType');
        }
      },
      sortKey: 'companyType',
      minWidth: 180,
    },
    {
      id: 'type',
      label: 'User roles',
      handleShowText: (value) => {
        let transformedText = '';
        switch (value) {
          case 'COMPANY_ADMIN':
            transformedText = 'Company admin';
            break;
          default:
            transformedText = 'Employee';
            break;
        }
        return transformedText;
      },
    },
    {
      id: 'status',
      label: 'Status',
      content: (row) => renderStatus(row?.status),
      onSort: (order) => {
        if (order === 'asc') {
          setOrder('status');
        } else {
          setOrder('-status');
        }
      },
      sortKey: 'status',
      minWidth: 150,
    },
    {
      id: 'createdAt',
      label: 'Created date',
      handleShowText: (value) =>
        formatLongDate(value, {
          isMonthShort: true,
        }),
      onSort: (order) => {
        if (order === 'asc') {
          setOrder('createdAt');
        } else {
          setOrder('-createdAt');
        }
      },
      sortKey: 'createdAt',
      minWidth: 180,
    },
    {
      id: 'action',
      label: '',
      content: function renderActionButton(row) {
        if (row?.status === 'INACTIVATED') {
          return null;
        }
        return (
          <Box display="flex">
            <Button
              color="primary"
              variant="contained"
              onClick={() => onImpersonate(row)}
              disabled={row?.status === ADMIN_USER_STATUS.BLOCKED}
            >
              impersonate
            </Button>
            {row?.status === ADMIN_USER_STATUS.BLOCKED ? (
              <Tooltip title="Unblock" placement="top" arrow>
                <IconButton
                  onClick={() => {
                    setUserIds([row?._id || row.id]);
                    setOpenConfirmDialog(true);
                  }}
                >
                  <LockOpenIcon color="primary" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Block" placement="top" arrow>
                <IconButton
                  onClick={() => {
                    setUserIds([row?._id || row.id]);
                    setOpenBlockDialog(true);
                  }}
                >
                  <LockIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
      customStyle: {
        position: 'sticky',
        right: 0,
        background: 'white',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
      },
    },
  ];

  const arrayButtons = [
    {
      onClick: handleCloseForm,
      label: 'Cancel',
      styles: { marginRight: '8px' },
    },
    {
      onClick: handleSubmit(handleBlockUsers),
      label: 'Submit',
      variant: 'contained',
    },
  ];

  return (
    <>
      <FruproPage>
        <FormDialog
          open={openDetailsModal}
          onClose={() => setOpenDetailsModal(false)}
          title={dialogTitle}
          buttons={[
            {
              onClick: () => setOpenDetailsModal(false),
              label: 'Cancel',
            },
          ]}
        >
          {detailsList
            ?.sort((a, b) => a.label.localeCompare(b.label))
            ?.map((item) => (
              <Box
                key={item._id || item.id}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={2}
                sx={{ width: 600 }}
              >
                <Typography variant="h6" fontSize={14} fontWeight="bold">
                  {item.label}
                </Typography>

                <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                  {item.value}
                </Typography>
              </Box>
            ))}
        </FormDialog>
        <FormDialog
          open={openBlockDialog}
          onClose={handleCloseForm}
          buttons={arrayButtons}
          title="Block reason"
        >
          <ControlledTextfield
            control={control}
            margin="normal"
            required
            fullWidth
            id="blockReason"
            label="Block reason"
            name="blockReason"
            autoComplete="blockReason"
            error={!!errors.blockReason}
            helperText={errors.blockReason?.message}
            sx={{ marginBottom: '16px', minWidth: '400px' }}
          />
        </FormDialog>

        <Dialog
          open={openConfirmDialog}
          title="Unblock user"
          labelConfirmButton="unblock"
          content="Are you sure to unblock this user?"
          onClose={() => setOpenConfirmDialog(false)}
          onSave={handleUnblockUsers}
        />

        <Table
          columns={columns}
          title="User list"
          useHookApi={useGetUserListQuery}
          type={USERS_TABLE}
          renderFilter={renderFilter}
          statusFilter={statusFilter}
          roleFilter={roleFilter}
          shouldRefetch={shouldRefetch}
          setShouldRefetch={setShouldRefetch}
          order={order}
        />
      </FruproPage>
      <LoadingIndicator loading={isImpersonateLoading} />
    </>
  );
};

UserList.propTypes = {};

export default UserList;
