import {
  HOME_PAGE_PATH,
  LOGIN_PATH,
  SETTING_PAGE_PATH,
  USERS_PAGE_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD,
  ACCOUNT_PATH,
  USER_LIST_PATH,
  COMPANY_PATH,
  CREATE_PASSWORD,
  DONATE_EMAIL_PATH,
  DONATE_CENTER_PATH,
  COMPANY_DETAIL_PATH,
  COMPANY_REGISTRATION_PATH,
  BRAND_PATH,
  SUB_CATEGORY_PATH,
  SUB_CATEGORY_DETAIL_PATH,
  CURRENCY_PATH,
} from 'constants/paths';
import DashBoard from 'modules/dashboard/DashBoard';
import Login from 'modules/login';
import AdminUsers from 'modules/admin-users';
import Setting from 'modules/setting/Setting';
import ForgotPassword from 'modules/forgot-password/ForgotPassword';
import ResetPassword from 'modules/reset-password/ResetPassword';
import Account from 'modules/account/Account';
import UserList from 'modules/user-list/UserList';
import Companies from 'modules/companies';
import CompanyDetailContainer from 'modules/companies/company-detail/CompanyDetailContainer';
import DonateEmails from 'modules/donate-emails/DonateEmails';
import DonationCenterContainer from 'modules/donate-emails/donation-center/DonationCenterContainer';
import SubCategories from 'modules/sub-categories/SubCategories';
import SubCategory from 'modules/sub-categories/sub-category/SubCategory';
import React from 'react';
import { Switch } from 'react-router-dom';
import CreatePassword from 'modules/create-password/CreatePassword';
import CompanyRegistration from 'modules/company-registration';
import Currency from 'modules/currency/CurrencyContainer';
import Brand from 'modules/brand';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const AppRoutes = () => (
  <Switch>
    <PublicRoute path={LOGIN_PATH} component={Login} exact />
    <PublicRoute path={FORGOT_PASSWORD_PATH} component={ForgotPassword} exact />
    <PublicRoute path={RESET_PASSWORD} component={ResetPassword} exact />
    <PublicRoute path={CREATE_PASSWORD} component={CreatePassword} exact />
    <PrivateRoute path={USERS_PAGE_PATH} component={AdminUsers} exact />
    <PrivateRoute path={HOME_PAGE_PATH} component={DashBoard} exact />
    <PrivateRoute path={SETTING_PAGE_PATH} component={Setting} exact />
    <PrivateRoute path={ACCOUNT_PATH} component={Account} exact />
    <PrivateRoute path={USER_LIST_PATH} component={UserList} exact />
    <PrivateRoute path={COMPANY_PATH} component={Companies} exact />
    <PrivateRoute
      path={COMPANY_DETAIL_PATH}
      component={CompanyDetailContainer}
      exact
    />
    <PrivateRoute path={DONATE_EMAIL_PATH} component={DonateEmails} exact />
    <PrivateRoute
      path={DONATE_CENTER_PATH}
      component={DonationCenterContainer}
      exact
    />
    <PrivateRoute
      path={COMPANY_REGISTRATION_PATH}
      component={CompanyRegistration}
      exact
    />
    <PrivateRoute path={BRAND_PATH} component={Brand} exact />
    <PrivateRoute path={SUB_CATEGORY_PATH} component={SubCategories} exact />
    <PrivateRoute
      exact
      component={SubCategory}
      path={SUB_CATEGORY_DETAIL_PATH}
    />
    <PrivateRoute
      exact
      component={Currency}
      path={CURRENCY_PATH}
    />
  </Switch>
);

export default AppRoutes;
