import React, { useState } from 'react';
import { Grid, Tooltip, IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Dialog, ControlledTextfield, FormDialog } from 'components';
import { useSelector } from 'react-redux';
import { adminUserApi } from 'services/adminUserApi';
import { ADMIN_USER_STATUS } from 'constants/adminUserStatus';
import { findKeyIndex } from 'utils/findKey';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const styleIcon = {
  marginRight: '8px',
};

const ACTION_ADMIN = {
  delete: 'delete',
  block: 'block',
  unblock: 'unblock',
};

const ActionAdminUsers = (props) => {
  const {
    data,
    performBlockAdminUser,
    performUnBlockAdminUser,
    performDeleteAdminUser,
  } = props;
  const [open, setOpen] = useState(false);
  const [openBlockDialog, setOpenBlockDialog] = useState(false);
  const [action, setAction] = useState('');

  const dataSelector = useSelector(
    (state) => state[adminUserApi.reducerPath].queries
  );

  const queries =
    dataSelector[
      Object.keys(dataSelector)[
        findKeyIndex(Object.keys(dataSelector), 'listAdminUsers')
      ]
    ]?.originalArgs;

  const schema = yup
    .object({
      blockReason: yup.string().required('Block reason is required'),
    })
    .required();

  const {
    control,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm({ mode: 'all', resolver: yupResolver(schema) });

  const handleCloseForm = () => {
    clearErrors();
    reset({ blockReason: '' });
    setOpenBlockDialog(false);
  };
  const handleBlockUser = (formData) => {
    performBlockAdminUser({
      blockReason: formData.blockReason,
      adminId: data.id,
      query: queries,
    });
    reset({ blockReason: '' });
    setOpenBlockDialog(false);
  };

  const handleConfirmAction = () => {
    if (action === ACTION_ADMIN.block) {
      performBlockAdminUser({ adminId: data.id, query: queries });
    } else if (action === ACTION_ADMIN.unblock) {
      performUnBlockAdminUser({ adminId: data.id, query: queries });
    } else if (action === ACTION_ADMIN.delete) {
      performDeleteAdminUser({ adminId: data.id, query: queries });
    }
    setOpen(false);
  };

  const renderMenuItem = () => {
    const options = [
      {
        id: 1,
        icon: <DeleteIcon sx={styleIcon} color="error" />,
        label: 'Delete',
        onClick: () => {
          setOpen(true);
          setAction(ACTION_ADMIN.delete);
        },
      },
    ];

    if (data.status === ADMIN_USER_STATUS.BLOCKED) {
      options.unshift({
        id: 2,
        icon: <LockOpenIcon sx={styleIcon} color="primary" />,
        label: 'Unlock',
        onClick: () => {
          setOpen(true);
          setAction(ACTION_ADMIN.unblock);
        },
      });
    } else if (data.status === ADMIN_USER_STATUS.ACTIVATED) {
      options.unshift({
        id: 3,
        icon: <LockIcon />,
        label: 'Block',
        onClick: () => {
          setOpenBlockDialog(true);
          setAction(ACTION_ADMIN.block);
        },
      });
    }

    return options.map((item) => (
      <Grid item key={item.id} onClick={item.onClick}>
        <Tooltip title={item.label} placement="top" arrow>
          <IconButton>{item.icon}</IconButton>
        </Tooltip>
      </Grid>
    ));
  };
  return (
    <>
      <Grid container justifyContent="flex-end">
        {renderMenuItem()}
      </Grid>
      <Dialog
        open={open}
        title={`${ACTION_ADMIN[action]} admin user`}
        labelConfirmButton={ACTION_ADMIN[action]}
        content={`Are you sure to ${ACTION_ADMIN[action]} this admin user?`}
        onClose={() => setOpen(false)}
        onSave={handleConfirmAction}
      />
      <FormDialog
        open={openBlockDialog}
        onClose={handleCloseForm}
        buttons={[
          {
            onClick: handleCloseForm,
            label: 'Cancel',
            styles: { marginRight: '8px' },
          },
          {
            onClick: handleSubmit(handleBlockUser),
            label: 'Submit',
            variant: 'contained',
          },
        ]}
        labelConfirmButton={ACTION_ADMIN[action]}
        title="Block reason"
      >
        <ControlledTextfield
          control={control}
          margin="normal"
          required
          fullWidth
          id="blockReason"
          label="Block reason"
          name="blockReason"
          autoComplete="blockReason"
          error={!!errors.blockReason}
          helperText={errors.blockReason?.message}
          sx={{ marginBottom: '16px', minWidth: '400px' }}
        />
      </FormDialog>
    </>
  );
};

ActionAdminUsers.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  performBlockAdminUser: PropTypes.func,
  performUnBlockAdminUser: PropTypes.func,
  performDeleteAdminUser: PropTypes.func,
};

ActionAdminUsers.defaultProps = {
  data: {},
  performBlockAdminUser: () => {},
  performUnBlockAdminUser: () => {},
  performDeleteAdminUser: () => {},
};

export default ActionAdminUsers;
