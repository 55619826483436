import { useState, useEffect } from 'react';
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
} from '@mui/material';
import {
  NotificationsOutlined as NotificationsIcon,
  Input as InputIcon,
} from '@mui/icons-material';
import { LOGIN_PATH } from 'constants/paths';
import { Redirect } from 'react-router-dom';
import { useLogoutMutation } from 'services/adminUserApi';
import { toast } from 'react-toastify';

const customId = 'toast-logout-id';

const Header = () => {
  const [notifications] = useState([]);

  const [performLogout, { isSuccess, isLoading, error }] = useLogoutMutation();

  const handleLogout = () => {
    performLogout();
  };

  if (isSuccess) {
    return <Redirect to={LOGIN_PATH} />;
  }

  return (
    <AppBar elevation={0}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }} />
        <Tooltip title="Notifications">
          <IconButton color="inherit" size="large">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title="Logout">
          <IconButton
            color="inherit"
            size="large"
            onClick={handleLogout}
            disabled={isLoading}
          >
            <InputIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
