import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { 
  LockOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  CssBaseline,
  Link as MaterialLink,
  Box,
  Typography,
  Container,
  Link
} from '@mui/material';
import { PasswordField } from 'components';
import { useRenewalPasswordMutation, useVerifyTokenMutation } from 'services/authenticationApi';
import { toast } from 'react-toastify';
import { PASSWORD_REGEX } from 'utils/constants';
import { useHistory } from 'react-router';
import { LOGIN_PATH } from 'constants/paths';

const schema = yup.object().shape({
  password: yup
    .string()
    .matches(
      PASSWORD_REGEX,
      "*Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    )
    .required('This is required'),
  confirmPassword: yup.string()
    .oneOf(
      [yup.ref("password"), null],
      "*Password and Confirm Password must be match"
    )
    .matches(
      PASSWORD_REGEX,
      "*Confirm Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    )
    .required("*This is required"),
})

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <MaterialLink color="inherit" href="http://localhost:3000">
        FruPro
      </MaterialLink>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function ResetPassword() {
  const [performVerifyToken, { 
    isSuccess: isSuccessVerify, 
    error: errorVerify, 
  }] = useVerifyTokenMutation();

  const [performRenewalPassword, { 
    isSuccess: isSuccessRenewal, 
    error: errorRenewal, 
    isLoading
  }] = useRenewalPasswordMutation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ mode: 'all', resolver: yupResolver(schema) });

  const [showResetForm, setShowResetForm] = useState(false);

  const history = useHistory();
  const token = new URLSearchParams(history.location.search).get('token');

  const handleRenewalPassword = (data) => {
    performRenewalPassword({
      password: data.password,
      token,
    });
  };

  useEffect(() => {
    performVerifyToken({ token });
  }, []);

  useEffect(() => {
    if (isSuccessVerify) {
      setShowResetForm(true);
    }
  }, [isSuccessVerify]);

  useEffect(() => {
    if (isSuccessRenewal) {
      const message = 'Your password has been successfully changed. Please login';
      toast.success(message);
      history.push(LOGIN_PATH);
    }
  }, [isSuccessRenewal]);

  return (
    <>
      {
        showResetForm && (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <Typography 
                variant="body1" 
                sx={{
                  pt: 2
                }}
              >
                Valid Password must be at least 8 characters including number, uppercase
                letter, lowercase letter and special special characters
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit(handleRenewalPassword)}
                noValidate
                sx={{
                  mt: 1,
                  width: '100%'
                }}
              >
                <PasswordField
                  control={control}
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
                <PasswordField
                  control={control}
                  margin="normal"
                  required
                  fullWidth
                  id="confirm-password"
                  label="Confirm password"
                  name="confirmPassword"
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  color="primary"
                  disabled={isLoading}
                >
                  {isLoading ? 'Submitting' : 'Submit'}
                </Button>
              </Box>
              <Link href={LOGIN_PATH}>
                Back to Sign in
              </Link>
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Container>
        )
      }
    </>
  );
}
