import React from "react";
import { createStyles, makeStyles } from '@mui/styles';
import { CircularProgress, Backdrop } from '@mui/material';
import PropTypes from 'prop-types';


export default function LoadingIndicator({ loading }) {
  const useStyles = makeStyles((theme) =>
    createStyles({
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      },
    })
  );

  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress />
    </Backdrop>
  );
}

LoadingIndicator.propTypes = {
  loading: PropTypes.bool
}

LoadingIndicator.defaultProps= {
  loading: false
}
