import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  RemoveRedEye as RemoveRedEyeIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';

import { COMPANY_REQUEST_STATUS, COMPANY_REQUEST_TYPE } from 'constants/companyStatus';
import { companyRegistrationApi } from 'services/companyRegistrationApi';
import { useSelector } from 'react-redux';
import { ControlledTextfield, FormDialog, Dialog } from 'components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ApproveNewRequestDialog from './ApproveNewRequestDialog';
import ApproveChangeRequestDialog from './ApproveChangeRequestDialog';

const styleIcon = {
  marginRight: '8px',
};

const useStyles = makeStyles((theme) => ({

}));

const schema = yup
  .object({
    rejectionReason: yup.string().required('Rejection reason is required'),
  })
  .required();

const ActionCompanyRegistration = (props) => {
  const {
    rowData,
    setOpen,
    performRequestDetail,
    performApproveCompanyRequest,
    performRejectCompanyRequest,
    setShouldRefetch
  } = props;
  const classes = useStyles();

  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [openApproveNewRequestDialog, setOpenApproveNewRequestDialog] = useState(false);
  const [openApproveChangeRequestDialog, setOpenApproveChangeRequestDialog] = useState(false);

  const {
    control,
    formState: { errors },
    reset,
    clearErrors,
    handleSubmit,
  } = useForm({ mode: 'all', resolver: yupResolver(schema) });

  const data = useSelector(
    (state) => state[companyRegistrationApi.reducerPath].queries
  );

  const queries =
    data[Object.keys(data)[Object.keys(data).length - 1]].originalArgs;

  const handleCloseFormDialog = () => {
    clearErrors();
    reset({ rejectionReason: '' });
    setOpenRejectDialog(false);
  };

  const handleRejectRequest = (formData) => {
    performRejectCompanyRequest({
      rejectionReason: formData.rejectionReason,
      companyRequestId: rowData?.id,
      query: queries,
    });
    reset({ rejectionReason: '' });
    setOpenRejectDialog(false);
  };

  const handleApproveNewRequest = () => {
    performApproveCompanyRequest({
      companyRequestId: rowData?.id,
      query: queries,
    });
    setOpenApproveNewRequestDialog(false);
  }

  const renderMenuItem = () => {
    const options = [
      {
        id: 1,
        icon: <RemoveRedEyeIcon color="primary" sx={styleIcon} />,
        label: 'View details',
        onClick: () => {
          setOpen(true);
          performRequestDetail({ companyRequestId: rowData?.id });
        },
      },
    ];
    if (
      ![
        COMPANY_REQUEST_STATUS.APPROVED,
        COMPANY_REQUEST_STATUS.REJECT,
      ].includes(rowData.status)
    ) {
      options.push(
        {
          id: 2,
          icon: <CheckCircleIcon color="success" sx={styleIcon} />,
          label: 'Approve',
          onClick: () => {
            if (rowData?.requestType === COMPANY_REQUEST_TYPE.NEW) {
              setOpenApproveNewRequestDialog(true);
            } else {
              setOpenApproveChangeRequestDialog(true);
            }
          },
        },
        {
          id: 3,
          icon: <CancelIcon color="error" sx={styleIcon} />,
          label: 'Reject',
          onClick: () => {
            setOpenRejectDialog(true);
          },
        }
      );
    }
    return options.map((item) => (
      <Grid item key={item.id} onClick={item.onClick}>
        <Tooltip title={item.label} placement="top" arrow>
          {item.icon}
        </Tooltip>
      </Grid>
    ));
  };

  return (
    <>
      <FormDialog
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        buttons={[
          {
            onClick: handleCloseFormDialog,
            label: 'Cancel',
            styles: { marginRight: '8px' },
          },
          {
            onClick: handleSubmit(handleRejectRequest),
            label: 'Submit',
            variant: 'contained',
          },
        ]}
        title="Rejection reason"
      >
        <ControlledTextfield
          control={control}
          margin="normal"
          required
          fullWidth
          id="rejectionReason"
          label="Rejection reason"
          name="rejectionReason"
          error={!!errors.rejectionReason}
          helperText={errors.rejectionReason?.message}
          sx={{ marginBottom: '16px', minWidth: '400px' }}
        />
      </FormDialog>
      <Grid container>{renderMenuItem()}</Grid>
      <ApproveNewRequestDialog
        open={openApproveNewRequestDialog}
        handleClose={() => setOpenApproveNewRequestDialog(false)}
        onSubmit={handleApproveNewRequest}
      />
      <ApproveChangeRequestDialog
        open={openApproveChangeRequestDialog}
        handleClose={() => setOpenApproveChangeRequestDialog(false)}
        companyId={rowData?.company?._id}
        setShouldRefetch={setShouldRefetch}
      />
    </>
  );
};

ActionCompanyRegistration.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rowData: PropTypes.object,
  setOpen: PropTypes.func,
  performRequestDetail: PropTypes.func,
  performApproveCompanyRequest: PropTypes.func,
  performRejectCompanyRequest: PropTypes.func,
  setShouldRefetch: PropTypes.func
};

ActionCompanyRegistration.defaultProps = {
  rowData: {},
  setOpen: () => {},
  performRequestDetail: () => {},
  performApproveCompanyRequest: () => {},
  performRejectCompanyRequest: () => {},
  setShouldRefetch: () => {}
};

export default ActionCompanyRegistration;
