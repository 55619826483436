import {
  Box,
  Container,
  Grid
} from '@mui/material';
import LoadingIndicator from 'components/LoadingIndicator';
import { useEffect } from 'react';
import { useState } from 'react';
import { useGetMeQuery } from 'services/adminUserApi';
import { useGetCountriesQuery } from 'services/info';
import AdminProfile from './AdminProfile';

const Account = () =>  {
  const { data, isLoading } = useGetMeQuery();
  const { data: countriesData, isSuccess, refetch } = useGetCountriesQuery();
  const [adminProfile, setAdminProfile] = useState();

  useEffect(() => {
    setAdminProfile(data);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [])

  return (
    <>
    <LoadingIndicator loading={isLoading} />
    <Box
      sx={{
        minHeight: '100%',
        py: 3
      }}
    >
      {adminProfile && countriesData && (
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={10}
              md={8}
              xs={12}
            >
              <AdminProfile 
                adminProfile={adminProfile}
                setAdminProfile={setAdminProfile}
                countries={countriesData}
              />
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
    </>
  );
}

export default Account;
