import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './baseQueryWithReAuth';

export const impersonation = createApi({
  reducerPath: 'impersonation',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    impersonate: builder.mutation({
      query: ({ userId }) => ({
        url: `/api/impersonation/${userId}`,
        method: 'POST',
      }),
      transformResponse: (response) => response,
    }),
  }),
});

export const { useImpersonateMutation } = impersonation;
