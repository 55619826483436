import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog as MUIDialog, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';

const StyledDialog = styled(MUIDialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root.MuiDialog-paper': {
    padding: '32px',
  },
  '& h1': {
    marginTop: 0,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
}));

const Dialog = (props) => {
  const { open, title, content, onClose, onSave, labelConfirmButton, className } = props;
  return (
    <StyledDialog className={className} open={open} onClose={onClose}>
      <h1>{title}</h1>
      <Grid container justifyContent="center">
        <Grid item>
          <WarningIcon color="warning" sx={{ fontSize: '60px' }} />
        </Grid>
      </Grid>
      <p style={{ textAlign: 'center' }}>{content}</p>
      <Grid className="button-group" container spacing={1}>
        <Grid item>
          <Button variant="outlined" sx={{ width: '150px' }} onClick={onClose}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" sx={{ width: '150px' }} onClick={onSave}>
            {labelConfirmButton}
          </Button>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  labelConfirmButton: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  className: PropTypes.string
};

Dialog.defaultProps = {
  title: '',
  content: '',
  labelConfirmButton: 'Save',
  onClose: () => {},
  onSave: () => {},
  className: ''
};

export default Dialog;
