import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ControlledTextfield } from 'components';
import PropTypes from 'prop-types';
import { useUpdateDonateEmailMutation } from 'services/donateEmailsApi';
import { toast } from 'react-toastify';

const schema = yup
  .object({
    name: yup.string().required('Name is required'),
    address: yup.string().required('Address is required'),
    long: yup
      .number()
      .typeError('Long must be a number')
      .required('Long is required'),
    lat: yup
      .number()
      .typeError('Lat must be a number')
      .required('Lat is required'),
  })
  .required();

const DonationCenterInfor = ({
  donationCenter,
  donateId,
  refetchDonationCenter,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: donationCenter?.name,
      address: donationCenter?.address,
      long: donationCenter?.location?.long,
      lat: donationCenter?.location?.lat,
    },
    resolver: yupResolver(schema),
  });

  const [performUpdateDonateEmail, { isSuccess, isLoading }] =
    useUpdateDonateEmailMutation();

  const onUpdateDonationCenter = (data) => {
    performUpdateDonateEmail({
      data,
      donateId,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Update donate email successfully');
      refetchDonationCenter();
    }
  }, [isSuccess]);

  return (
    <Card elevation={0} sx={{ maxWidth: '80%', margin: 'auto' }}>
      <CardHeader
        subheader="The information can be edited"
        title="Donation center"
      />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <ControlledTextfield
              fullWidth
              label="Name"
              name="name"
              variant="outlined"
              defaultValue={donationCenter?.name}
              required
              control={control}
              error={!!errors.name}
              helperText={errors.name?.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ControlledTextfield
              fullWidth
              label="Address"
              name="address"
              required
              variant="outlined"
              control={control}
              error={!!errors.address}
              helperText={errors.address?.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ControlledTextfield
              fullWidth
              label="Long"
              name="long"
              required
              variant="outlined"
              defaultValue={donationCenter?.location?.long}
              control={control}
              error={!!errors.long}
              helperText={errors.long?.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ControlledTextfield
              fullWidth
              label="Lat"
              name="lat"
              defaultValue={donationCenter?.location?.lat}
              required
              variant="outlined"
              control={control}
              error={!!errors.lat}
              helperText={errors.lat?.message}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit(onUpdateDonationCenter)}
          disabled={isLoading}
        >
          Save
        </Button>
      </Box>
    </Card>
  );
};

DonationCenterInfor.propTypes = {
  donationCenter: PropTypes.instanceOf(Object),
  donateId: PropTypes.string,
  refetchDonationCenter: PropTypes.func,
};
DonationCenterInfor.defaultProps = {
  donationCenter: {},
  donateId: '',
  refetchDonationCenter: () => {},
};

export default DonationCenterInfor;
