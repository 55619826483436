/* eslint-disable */
import { FruproPage } from 'components';
import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  Box,
} from '@mui/material';
import { useGetCurrenciesQuery, useToggleCurrencyStatusMutation } from 'services/info';
import { IOSSwitch } from 'components/StyledComponents';
import LoadingIndicator from 'components/LoadingIndicator';
import { toast } from 'react-toastify';

const CurrencyContainer = () => {
  const { data, refetch, isLoading, isFetching } = useGetCurrenciesQuery();
  const [
    performToggleCurrencyStatus,
    { isSuccess: isToggleSuccess, error, isLoading: isToggleLoading },
  ] = useToggleCurrencyStatusMutation();

  const [currencies, setCurrencies] = useState();

  useEffect(() => {
    if (data) {
      setCurrencies(data);
    }
  }, [data]);


  useEffect(() => {
    if (isToggleSuccess) {
      toast.success('You have just toggled currency status successfully!')
    }
  }, [isToggleSuccess]);

  useEffect(() => {
    if (error) {
      toast.error('Toggle status fail!')
    }
  }, [error]);

  const checkCurrencyPublish = (currencies) => {
    return currencies?.map((item) => item.status).some((item) => Boolean(item))
  }

  const onSwitchChange = async (event, currency) => {
    const checked = event.target.checked;
    const newCurrencies = [...currencies]?.map((item) => {
      if (item.id === currency.id) {
        return {
          ...item,
          status: checked ? 1 : 0
        }
      }

      return item;
    });

    const isAtLeastOneCurrencyPublish = checkCurrencyPublish(newCurrencies);

    if (isAtLeastOneCurrencyPublish) {
      setCurrencies(newCurrencies);
      performToggleCurrencyStatus({ currencyId: currency.id });
    } else {
      toast.error("You must have at least one publishing currency!")
    }
  }

  const columns = [
    { 
      id: 'name',
      label: 'Currency Name',
    },
    {
      id: 'symbol',
      label: 'Symbol'
    },
    {
      id: 'status',
      label: 'Status',
      content: (row) => <IOSSwitch checked={Boolean(row?.status)} onChange={(e) => onSwitchChange(e, row)} />
    },
  ];

  return (
    <FruproPage>
      <LoadingIndicator loading={isToggleLoading} />
      <h2 style={{ marginTop: 0 }}>Currency</h2>
      {isLoading || isFetching ? <LinearProgress /> : <Box height="4px" />}
      <Paper sx={{ width: '100%', overflow: 'hidden', mt: 1 }} elevation={2}>
        <TableContainer>
          <MUITable stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={`id-${column.id}`}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: 'bold',
                      fontSize: 14,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currencies?.map((item, itemIndex) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={item._id || item.id || itemIndex}
                >
                  {columns.map((column, index) => {
                    const value = column.content
                      ? column.content(item, index)
                      : item[column.id];
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          fontSize: 14,
                          cursor: 'pointer',
                        }}
                        onClick={
                          column.onClick ? () => column.onClick(item) : null
                        }
                      >
                        {column.handleShowText
                          ? column.handleShowText(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
      </Paper>
    </FruproPage>
  );
};

export default CurrencyContainer;
