import React, { useState, useEffect } from 'react';
import { COMPANY_PATH } from 'constants/paths';
import { makeStyles } from '@mui/styles';
import { FruproPage } from 'components';
import { Box, Tab, Button } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingIndicator from 'components/LoadingIndicator';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  useGetDetailCompanyQuery,
  useListDetailCompanyQuery,
} from 'services/companyApi';
import {
  useApproveCompanyMutation,
  useVerifyCompanyMutation
} from 'services/companyRegistrationApi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Dialog } from 'components';
import { COMPANY_STATUS } from 'constants/companyStatus';
import { toast } from 'react-toastify';
import { CHANGE_STATUS_DIALOG_DESCRIPTION, initData, UPDATE_SUCCESS_MESSAGE } from './constants';
import CompanyDetailInfor from './CompanyDetailInfor';
import CompanyConfidentialityInfor from './CompanyConfidentialityInfor';
import CompanySubscriptionDetails from './CompanySubscriptionDetails';

const useStyles = makeStyles(() => ({
  tabsList: {
    position: 'relative',
  },
  buttonList: {
    alignSelf: 'center',
    position: 'absolute',
    right: 0,
    display: 'flex',
    listStyle: 'none',
    margin: '0 -4px'
  },
  buttonItem: {
    padding: '0 4px'
  },
  customDialog: {
    "& .button-group": {
      justifyContent: "flex-end",
      marginTop: 8
    }
  }
}));

const schema = yup.object().shape({
  companyStatus: yup.string().required("This is required")
});

const CompanyDetailContainer = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('detailInfor');
  const [detailCompany, setDetailCompany] = useState();
  const [openEditCompanyInfo, setOpenEditCompanyInfo] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const generalDetailCompany = location.state;

  const { data, isLoading } = useListDetailCompanyQuery(id);
  const { data: companyInfo, isLoading: isGetDetailCompanyLoading } = useGetDetailCompanyQuery(id);

  const [performApproveCompany, {
    data: approveData,
    isLoading: isApproveLoading,
    isSuccess: isApproveSuccess
  }] = useApproveCompanyMutation();
  const [performVerifyCompany, {
    data: verifyData,
    isLoading: isVerifyLoading,
    isSuccess: isVerifySuccess
  }] = useVerifyCompanyMutation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm({ 
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: initData
  });



  const watchCompanyStatus = watch("companyStatus");

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getInitialData = () => {
    const { status } = companyInfo;

    const initCompanyData = {
      companyStatus: status !== COMPANY_STATUS.PENDING ? status : ""
    }

    reset(initCompanyData);
  }

  useEffect(() => {
    if (companyInfo) {
      getInitialData();
    }
  }, [companyInfo]);

  useEffect(() => {
    if (data) {
      setDetailCompany(data);
    }
  }, [data]);

  useEffect(() => {
    if (isApproveSuccess || isVerifySuccess) {
      toast.success(UPDATE_SUCCESS_MESSAGE);
      setOpenEditCompanyInfo(false);
    }
  }, [isApproveSuccess, isVerifySuccess]);

  const tabsList = [
    {
      key: 'detailInfor',
      label: 'General infomation',
      component: (
        <CompanyDetailInfor 
          detailCompany={companyInfo}
          control={control}
          errors={errors}
          openEditCompanyInfo={openEditCompanyInfo}
        />
      ),
    },
    {
      key: 'confidentialityInfor',
      label: 'Confidentiality infomation',
      component: <CompanyConfidentialityInfor openEditCompanyInfo={openEditCompanyInfo} detailCompany={detailCompany} />,
    },
    {
      key: 'subscriptionDetails',
      label: 'Subscription Details',
      component: <CompanySubscriptionDetails detailCompany={detailCompany} />,
    },
  ];

  useEffect(() => {
    const initTab = tabsList[0]?.key;
    setActiveTab(initTab);
  }, []);

  const onSubmitCompanyStatus = (companyData) => {
    const { companyStatus } = companyData;
    
    switch (companyStatus) {
      case COMPANY_STATUS.APPROVED:
        performApproveCompany({ companyId: detailCompany.company });
        break;
      case COMPANY_STATUS.VERIFIED:
        if (companyInfo?.subscription) {
          performVerifyCompany({ companyId: detailCompany.company });
        } else {
          toast.error("The company need to subscription payment.");
        }
        break;
      default:
        break;
    }
  
    setOpenDialog(false);
  }

  const onClickSaveCompanyStatus = () => {
    if (detailCompany?.status === COMPANY_STATUS.PENDING) {
      setOpenDialog(true);
    } else {
      handleSubmit(onSubmitCompanyStatus)();
    }
  }

  const renderButtonItems = () => {
    if (openEditCompanyInfo) {
      return (
        <>
          <Box className={classes.buttonItem} component="li">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                getInitialData(detailCompany);
                setOpenEditCompanyInfo(false);
              }}
            >
              Cancel
            </Button>
          </Box>
          <Box className={classes.buttonItem} component="li">
            <Button
              color="primary"
              variant="contained"
              onClick={onClickSaveCompanyStatus}
              disabled={!watchCompanyStatus}
            >
              Ok
            </Button>
          </Box>
        </>
      )
    }

    return (
      <>
        <Box className={classes.buttonItem} component="li">
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenEditCompanyInfo(true)}
          >
            Edit
          </Button>
        </Box>
        <Box className={classes.buttonItem} component="li">
          <Button
            startIcon={<ArrowBackIcon />}
            color="primary"
            variant="contained"
            onClick={() => history.push(COMPANY_PATH)}
          >
            Back
          </Button>
        </Box>
      </>
    )
  }

  return (
    <FruproPage>
      <LoadingIndicator 
        loading={
          isLoading
          || isVerifyLoading
          || isApproveLoading
          || isGetDetailCompanyLoading}
      />
      {detailCompany && (
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={activeTab}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="Tabs"
                className={classes.tabsList}
              >
                {tabsList?.map((tab) => (
                  <Tab key={tab?.key} label={tab?.label} value={tab?.key} />
                ))}
                <Box className={classes.buttonList} component="ul">
                  {renderButtonItems()}
                  
                </Box>
              </TabList>
            </Box>
            {tabsList?.map((tab) => (
              <TabPanel key={tab?.key} value={tab?.key}>
                {tab?.component}
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      )}
      <Dialog
        className={classes.customDialog}
        open={openDialog}
        title="Warning"
        labelConfirmButton="Ok"
        content={CHANGE_STATUS_DIALOG_DESCRIPTION}
        onClose={() => setOpenDialog(false)}
        onSave={handleSubmit(onSubmitCompanyStatus)}
      />
    </FruproPage>
  );
};

export default CompanyDetailContainer;
