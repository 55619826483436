export function formatLongDate(date, option) {
  const { isMonthShort, isDay, isShowYear = true } = option;
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthNames = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];
  const days = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];

  const formatedDate = new Date(date);

  const dd = formatedDate.getDate();
  const mm = isMonthShort
    ? monthNamesShort[formatedDate.getMonth()]
    : monthNames[formatedDate.getMonth()];
  const yyyy = formatedDate.getFullYear();
  const day = days[formatedDate.getDay()];
  if (isDay) {
    return `${day} ${mm} ${dd} ${yyyy}`;
  }

  if (!isShowYear) {
    return `${mm} ${dd}`;
  }
  return `${mm} ${dd} ${yyyy}`;
}

export const queryObjSerialize = (obj) => {
  const str = [];
  Object.keys(obj).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key]) {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
  })
    
  return str.join("&");
};
