import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  MenuItem
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ControlledTextfield } from 'components';
import { COMPANY_STATUS_MENU } from 'modules/companies/company-detail/constants';
import { useVerifyCompanyMutation, useApproveCompanyMutation } from 'services/companyRegistrationApi';
import PropTypes from 'prop-types';
import { COMPANY_STATUS } from 'constants/companyStatus';
import { toast } from 'react-toastify';
import LoadingIndicator from 'components/LoadingIndicator';

const useStyles = makeStyles((theme) => ({
  contents: {
    paddingLeft: 24,
    paddingTop: 16,
    margin: '-4px 0',
    listStyle: 'decimal',
    '& li': {
      fontSize: 14,
      padding: '4px 0'
    }
  },
  customDialog: {
    '& .MuiDialogContent-root': {
      overflowY: 'unset'
    }
  }
}));

const schema = yup.object().shape({
  companyStatus: yup.string().required("This is required")
});

const ApproveChangeRequestDialog = (props) => {
  const { open, handleClose, companyId, setShouldRefetch } = props;
  const classes = useStyles();

  const [performApproveCompany, {
    data: approveData,
    isLoading: isApproveLoading,
    isSuccess: isApproveSuccess
  }] = useApproveCompanyMutation();

  const [performVerifyCompany, {
    data: verifyData,
    isLoading: isVerifyLoading,
    isSuccess: isVerifySuccess,
  }] = useVerifyCompanyMutation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useForm({ 
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      companyStatus: COMPANY_STATUS.APPROVED
    }
  });

  const watchCompanyStatus = watch('companyStatus');

  const onSubmitCompanyStatus = (data) => {
    const { companyStatus } = data;

    if (companyStatus === COMPANY_STATUS.APPROVED) {
      performApproveCompany({ companyId });
    } else {
      performVerifyCompany({ companyId });
    }
    handleClose();
  }

  useEffect(() => {
    if (isApproveSuccess || isVerifySuccess) {
      setShouldRefetch(true);
      toast.success('Company request has been approved successfully', {
        toastId: 'company-request-action-success-id',
      });
    }
  }, [isApproveSuccess || isVerifySuccess]);

  const renderDialogContent = () => {
    if (watchCompanyStatus === COMPANY_STATUS.VERIFIED) {
      return (
        <>
          <Typography gutterBottom paddingTop={2} fontWeight="bold">
            Verified status access right:
          </Typography>
          <Typography>
            This company type has been fully verified by the commercial 
            team performing KYC and KYB checks on these users and have received all 
            documentation needed from these users, these users will have full access to the platform as users have now:
          </Typography>
          <Box className={classes.contents} component="ul">
            <Box component="li">
              Users of this company will have full access to the platform as they do right now
            </Box>
            <Box component="li">
              Users of this company can make offers
            </Box>
            <Box component="li">
              Users of this company  can receive offers etc.
            </Box>
            <Box component="li">
              When these companies&apos; users create product listings, their product listings will show a blue tick 
              verification similar to Instagram or Twitter to show that these companies have been verified 
            </Box>
          </Box>
        </>
      )
    }

    return (
      <>
        <Typography gutterBottom paddingTop={2} fontWeight="bold">
            Confirmed status access right:
          </Typography>
        <Typography>
          This company has been approved onto the platform by a FruPro admin 
          from the Admin Panel but they will still have limited access:
        </Typography>
        <Box className={classes.contents} component="ul">
          <Box component="li">
            <b>NOT</b> be able to make an offer for other product listings on the platform
          </Box>
          <Box component="li">
            <b>NOT</b> be able to receive any offers for any product listings they have created on the platform
          </Box>
          <Box component="li">
            Able to create product listings
          </Box>
          <Box component="li">
            Able to message other users on the platform including having a company group chat
          </Box>
          <Box component="li">
            Able to add colleagues to their company
          </Box>
          <Box component="li">
            <b>NOT</b> be able to see other product listings on the platform’s pricing
          </Box>
          <Box component="li">
            <b>NOT</b> see &quot;Add to cart&quot; or &quot;Place offer&quot; buttons
          </Box>
          <Box component="li">
            The product listings created by these users will <b>NOT</b> show the pricing from their product listings, the product listings 
            they have created will have no pricing shown to other users of the platform, it will be blank to other users
          </Box>
        </Box>
      </>
      
    )
  }

  return (
    <>
      <LoadingIndicator loading={isApproveLoading || isVerifyLoading} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        className={classes.customDialog}
      >
        <DialogTitle id="alert-dialog-title">
          Change company status
        </DialogTitle>
        <DialogContent>
          <ControlledTextfield
            fullWidth
            label="Company status"
            name="companyStatus"
            variant="outlined"
            select
            required
            control={control}
            error={!!errors.companyStatus}
            helperText={errors.companyStatus?.message}
            InputLabelProps={{
              shrink: true,
            }}
          >
            {COMPANY_STATUS_MENU.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </ControlledTextfield>
          {renderDialogContent()}
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSubmit(onSubmitCompanyStatus)} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ApproveChangeRequestDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  companyId: PropTypes.string,
  setShouldRefetch: PropTypes.func
};

ApproveChangeRequestDialog.defaultProps = {
  open: false,
  handleClose: () => {},
  companyId: '',
  setShouldRefetch: () => {}
};

export default ApproveChangeRequestDialog;
