/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import { FormDialog } from 'components';

import { CircularProgress, Grid } from '@mui/material';

import CompanyRegistrationConInfor from './company-registration-detail/CompanyRegistrationConInfor';

const CompanyRegistrationDetail = (props) => {
  const { companyRequestDetail, isLoading, open, onClose } = props;

  return (
    <FormDialog
      open={open}
      onClose={onClose}
      title="Company registration details"
      fullWidth
      maxWidth="lg"
      buttons={[
        {
          onClick: onClose,
          label: 'Close',
        },
      ]}
    >
      {isLoading ? (
        <Grid container justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <CompanyRegistrationConInfor detailCompany={companyRequestDetail} />
      )}
    </FormDialog>
  );
};

CompanyRegistrationDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  companyRequestDetail: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
};

CompanyRegistrationDetail.defaultProps = {
  companyRequestDetail: {},
  open: false,
  onClose: () => {},
  isLoading: false,
};

export default CompanyRegistrationDetail;
