import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
}

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearCredentials: () => null,
  },
});

export const { clearCredentials } = usersSlice.actions;

export default usersSlice.reducer;
