import React from 'react';
import { Table, FruproPage } from 'components';
import { useListStatisticBrandsQuery } from 'services/brandApi';

const columns = [
  {
    id: 'brand',
    label: 'Brand',
    minWidth: 100,
  },
  {
    id: 'productType',
    label: 'Product type',
    minWidth: 100,
    content: (row) => (
      <span>{row?.subProductTypes?.map((item) => item.name).join(', ')}</span>
    ),
  },
  {
    id: 'productCategory',
    label: 'Product category',
    minWidth: 100,
    content: (row) => (
      <span>
        {row?.categories?.map((item) => item.name || item).join(', ')}
      </span>
    ),
  },
  {
    id: 'countryOfOrigin',
    label: 'Country of origin',
    minWidth: 100,
    content: (row) => (
      <span>{row?.countries?.map((item) => item.name).join(', ')}</span>
    ),
  },
];

const Brand = () => (
  <FruproPage>
    <Table
      title="Brands"
      columns={columns}
      useHookApi={useListStatisticBrandsQuery}
    />
  </FruproPage>
);

Brand.propTypes = {};

export default Brand;
